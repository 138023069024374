import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { parse, parseISO } from "date-fns";
import { DataFieldType, IRuleSet, Rule } from "~shared/types";
import { IPaymentProvider } from "~shared/types/paymentProvider";
import {
	IRegistrationFormAvailableShowResource,
	IRegistrationFormShowResource,
	isRegistrationFormClosed,
	isRegistrationFormPrivate,
} from "~shared/types/registrationForm";
import { registrationFormService } from "./registrationFormService";

const mapRuleFromResource = (rule: Rule) => {
	if (DataFieldType.DATETIME === rule.dataField.type) {
		return {
			...rule,
			value: rule.value ? parseISO(rule.value as string) : rule.value,
		} as Rule;
	}
	if (DataFieldType.DATE === rule.dataField.type) {
		return {
			...rule,
			value: rule.value ? parse(rule.value as string, "yyyy-MM-dd", new Date()) : rule.value,
		} as Rule;
	}
	return rule;
};
const mapRuleSetToResource = (ruleSet: IRuleSet) => ({
	...ruleSet,
	rules: ruleSet.rules.map(mapRuleFromResource),
});

const fromRegistrationFormResource = (registrationForm: IRegistrationFormShowResource) => {
	if (isRegistrationFormPrivate(registrationForm) || isRegistrationFormClosed(registrationForm)) {
		return registrationForm;
	}
	return {
		...registrationForm,
		ruleSets: registrationForm.ruleSets.map(mapRuleSetToResource),
		steps: registrationForm.steps.map((step) => {
			return {
				...step,
				children: step.children.map((sectionOrBlock) => {
					if (sectionOrBlock.entityType === "block") {
						return sectionOrBlock;
					}

					return {
						...sectionOrBlock,
						validationRules: sectionOrBlock.section.validationRules.map(mapRuleFromResource),
					};
				}),
			};
		}),
	};
};

const registrationFormDetailsFetcher = (formId: Id, token: string | null) => () =>
	registrationFormService
		.show(formId, token)
		.then((r) => r.data.data)
		.then(fromRegistrationFormResource);

export const useRegistrationFormDetailsQuery = (formId: Id, token: string | null) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: registrationFormService.keys.details(formId).concat(token || ""),
		queryFn: registrationFormDetailsFetcher(formId, token),
	});

	return {
		data,
		isLoading,
		isError,
	};
};

const registrationFormPreviewDetailsFetcher = (formId: Id) => () =>
	registrationFormService
		.previewShow(formId)
		.then((r) => r.data.data)
		.then(fromRegistrationFormResource);

export const useRegistrationFormPreviewDetailsQuery = (formId: Id) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: registrationFormService.keys.previewDetails(formId),
		queryFn: registrationFormPreviewDetailsFetcher(formId),
	});

	return {
		data: data as any as IRegistrationFormAvailableShowResource,
		isLoading,
		isError,
	};
};

const initialPaymentProviders: IPaymentProvider[] = [];
const registrationFormPaymentProvidersFetcher = (formId: Id) => () =>
	registrationFormService.getPaymentProviders(formId).then((r) => r.data.data);
export const useRegistrationFormPaymentProvidersQuery = (formId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: registrationFormService.keys.paymentProviders(formId),
		queryFn: registrationFormPaymentProvidersFetcher(formId),
	});

	return {
		data: data || initialPaymentProviders,
		isLoading,
	};
};
