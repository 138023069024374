import { DataFieldType } from "~shared/types";
import { RuleTypes } from "~shared/types/rules";
import { BaseRule, TranslatedError } from "./baseRule";

export class HasMinCharsRuleImpl extends BaseRule {
	type: RuleTypes = RuleTypes.HAS_MAX_CHARS;

	isValid(): boolean {
		if (this.value === undefined || this.value === null) {
			return false;
		}
		switch (this.dataField.type) {
			case DataFieldType.URL:
			case DataFieldType.EMAIL:
			case DataFieldType.LONG_TEXT:
			case DataFieldType.TEXT:
			case DataFieldType.CRYPT:
			case DataFieldType.PHONE:
			case DataFieldType.NUMBER:
				return `${this.value}`.length >= (this.ruleValue as number);
		}
		return false;
	}

	getErrorMessage(): TranslatedError {
		return {
			key: "Field has minimum {{count}} characters",
			values: { count: Number(this.ruleValue) },
		};
	}
}
