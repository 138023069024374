import { useLocale } from "@dgs/core";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getDateFnsLocale } from "~root/locale/getDateFnsLocale";
import { CurrentCompanion, useRegistrationForm } from "~root/registration/provider/registrationFormProviderContext";
import {
	toDataFieldRequestValues,
	toDataFieldTypeValidationErrors,
} from "~root/registration/provider/registrationFormProviderUtils";
import { createInitialValues, toDataFieldChildrenFromSection } from "~root/registration/registrationFormUtils";
import { ruleFactory } from "~root/registration/rules/ruleFactory";
import {
	DataFieldValue,
	DataFieldValues,
	IRegistrationFormChildSection,
	IRegistrationFormCompanionSection,
	Rule,
} from "~shared/types";

export const useRegistrationCompanionForm = (
	section: IRegistrationFormChildSection<IRegistrationFormCompanionSection>,
	currentCompanion: CurrentCompanion,
) => {
	const { locale } = useLocale();
	const dateFnsLocale = useMemo(() => getDateFnsLocale(locale), [locale]);
	const { t } = useTranslation();
	const { saveCompanion } = useRegistrationForm();

	const dataFields = useMemo(
		() => toDataFieldChildrenFromSection(section).map((dataField) => dataField.dataField),
		[section],
	);

	const initialValues = useMemo(
		() => createInitialValues(dataFields, currentCompanion?.companion.dataFieldValues),
		[currentCompanion?.companion.dataFieldValues, dataFields],
	);

	const validationRuleMap = useMemo(
		() =>
			section.section.validationRules.reduce(
				(acc, cur) => ({
					...acc,
					[cur.dataField.id]: [...(acc[cur.dataField.id] ?? []), cur],
				}),
				{} as Record<number, Rule[]>,
			),
		[section.section.validationRules],
	);

	const validate = (values: Record<number, any>) => {
		const dataFieldValidationRuleErrors: any = section.section.validationRules
			.map((rule) => ruleFactory({ ...rule, sectionId: section.section.id }, values[rule.dataField.id], true))
			.filter((rule) => !rule.isValid())
			.reduce(
				(errors, rule) => {
					const { key, values } = rule.getErrorMessage({ locale: dateFnsLocale });
					return {
						...errors,
						[rule.dataField.id]: t(key as any, values),
					};
				},
				{} as Record<string, any>,
			);

		return dataFields.reduce(toDataFieldTypeValidationErrors(values, t), dataFieldValidationRuleErrors);
	};

	const submitCompanion = useCallback(
		(values: DataFieldValues, afterSuccess?: () => void) => {
			const dataFieldValues: DataFieldValue[] = dataFields.map(toDataFieldRequestValues(values));
			saveCompanion({
				index: currentCompanion.index,
				companion: {
					guestId: currentCompanion?.companion.guestId ?? null,
					dataFieldValues: dataFieldValues.filter((v) => v.value !== undefined),
					isEditable: currentCompanion?.companion.isEditable ?? false,
				},
			});
			afterSuccess && afterSuccess();
		},
		[
			currentCompanion?.companion.guestId,
			currentCompanion?.companion.isEditable,
			currentCompanion.index,
			dataFields,
			saveCompanion,
		],
	);

	return { submitCompanion, initialValues, validate, validationRuleMap };
};
