import { Action, ActionTypes } from "~shared/types/actions";
import { AttachGroupAction } from "./attachGroupAction";
import { AttachTagAction } from "./attachTagAction";
import { OverwriteGroupsAction } from "./overwriteGroupsAction";
import { OverwriteTagsAction } from "./overwriteTagsAction";
import { SendEmailAction } from "./sendEmailAction";
import { ShowDataFieldAction } from "./showDataFieldAction";
import { ShowSectionAction } from "./showSectionAction";
import { ShowStepAction } from "./showStepAction";

export const actionFactory = (action: Action) => {
	switch (action.type) {
		case ActionTypes.SHOW_STEP:
			return new ShowStepAction(action.value.id);
		case ActionTypes.SHOW_SECTION:
			return new ShowSectionAction(action.value.id);
		case ActionTypes.SHOW_DATA_FIELD:
			return new ShowDataFieldAction(action.value);
		case ActionTypes.ATTACH_GROUP:
			return new AttachGroupAction(action.value.id);
		case ActionTypes.ATTACH_TAG:
			return new AttachTagAction(action.value.id);
		case ActionTypes.OVERWRITE_GROUPS:
			return new OverwriteGroupsAction(action.value.map((x) => x.id));
		case ActionTypes.OVERWRITE_TAGS:
			return new OverwriteTagsAction(action.value.map((x) => x.id));
		case ActionTypes.SEND_EMAIL:
			return new SendEmailAction(action.value.id);
	}
};
