import React, { useCallback, useState } from "react";

function isStateSetter<T>(callback: T | ((prevState: T) => T)): callback is (prevState: T) => T {
	return typeof callback === "function";
}
export function useSessionState<T>(
	key: string,
	defaultValue: T,
): [value: T, setValue: React.Dispatch<React.SetStateAction<T>>] {
	const sessionValue = sessionStorage.getItem(key);
	const [value, _setValue] = useState<T>(sessionValue ? JSON.parse(sessionValue) : defaultValue);

	const setValue = useCallback(
		(setState: ((prevState: T) => T) | T) => {
			if (isStateSetter(setState)) {
				_setValue((current) => {
					const next = setState(current);
					sessionStorage.setItem(key, JSON.stringify(next));
					return setState(next);
				});
			} else {
				sessionStorage.setItem(key, JSON.stringify(setState));
				_setValue(setState);
			}
		},
		[key],
	);

	return [value, setValue];
}
