import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { config } from "~root/config";
import { App } from "./App";

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

Sentry.init({
	dsn: (import.meta as any).env.VITE_SENTRY_DSN,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		}),
		new Sentry.Replay(),
	],
	tracesSampleRate: (import.meta as any).env.VITE_SENTRY_TRACES_SAMPLE_RATE,
	environment: (import.meta as any).env.VITE_SENTRY_ENVIRONMENT,
	release: (import.meta as any).env.VITE_SENTRY_RELEASE,
	enabled: config.mode === "production",
});

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
