import { Layout } from "@dgs/core";
import { ErrorBoundary } from "@sentry/react";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { PMApplicationBar } from "~root/PMApplicationBar";
import { PMNavBar } from "~root/PMNavBar";
import { Footer } from "~shared/ui";
import { ErrorPage } from "~shared/ui/ErrorPage";

const GlobalStyles = createGlobalStyle`
  html, body {
    font-size: ${({ theme }) => theme.fonts.sizes.normal};
  }
  ${({ theme }) => theme.global};
`;

export const Content: FC = () => {
	return (
		<>
			<GlobalStyles />
			<Layout
				header={<PMApplicationBar />}
				navigation={<PMNavBar />}
				content={
					<ErrorBoundary fallback={<ErrorPage />}>
						<Outlet />
					</ErrorBoundary>
				}
				footer={<Footer />}
			/>
		</>
	);
};
