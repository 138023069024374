import React, { useEffect } from "react";
import { useRegistrationForm } from "../provider/registrationFormProviderContext";
import { RenderRegistrationBlock } from "../RenderRegistrationBlock";

export const RegistrationScreeningConfirmedPage = () => {
	const { registrationForm, clearRegistration } = useRegistrationForm();

	useEffect(() => {
		clearRegistration();
	}, [clearRegistration]);

	return <RenderRegistrationBlock blockId={registrationForm.screeningStatusPositiveBlockId} />;
};
