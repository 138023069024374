import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardHeaderActions,
	CardHeading,
	Empty,
	H4,
	Icon,
	IconButton,
	Section,
} from "@dgs/core";
import { useFormikContext } from "formik";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DataField, IRegistrationFormChildSection, IRegistrationFormCompanionSection } from "~shared/types";
import { Companion } from "~shared/types/guest";
import { DataFieldLabelRenderer } from "./DataFieldLabelRenderer";
import { DataFieldValueRenderer } from "./DataFieldValueRenderer";
import { CurrentCompanion, useRegistrationForm } from "./provider/registrationFormProviderContext";
import { RegistrationFormCompanionDrawer } from "./RegistrationFormCompanionDrawer";
import { toDataFieldChildrenFromSection } from "./registrationFormUtils";

const SectionWrapper = styled(Section)`
	display: grid;
	gap: ${({ theme }) => theme.spacing(2)};
	padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledCardBody = styled(CardBody)`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: ${({ theme }) => theme.spacing(2)};
`;

const Header = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding-bottom: ${({ theme }) => theme.spacing(6)};

	${({ theme }) => theme.breakpoints.tablet} {
		justify-content: space-between;
	}
`;

const StyledValue = styled(DataFieldValueRenderer)`
	font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

const Heading = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(2)};
`;

const Count = styled.span`
	${({ theme }) => theme.forms.label};
	font-size: ${({ theme }) => theme.fonts.sizes.normal};
`;

const IconButtonWithTitle = styled(IconButton)`
	pointer-events: auto;
`;

interface Props {
	stepId: number;
	section: IRegistrationFormChildSection<IRegistrationFormCompanionSection>;
}

export const RegistrationCompanionSection: FC<Props> = ({ stepId, section }) => {
	const { t } = useTranslation();
	const { values } = useFormikContext<Record<number, any>>();
	const [currentCompanion, setCurrentCompanion] = useState<CurrentCompanion | null>(null);
	const { companions, removeCompanion } = useRegistrationForm();
	const dataFields = useMemo(() => toDataFieldChildrenFromSection(section).map((x) => x.dataField), [section]);

	const onNewClick = useCallback(() => {
		const emptyCompanion: Companion = {
			isEditable: false,
			guestId: null,
			dataFieldValues: dataFields.map((x) => ({ id: x.id, value: null })),
		};

		setCurrentCompanion({ index: -1, companion: emptyCompanion });
	}, [dataFields]);

	const currentCount = companions.length;
	const maxCount = section.section.companionSectionSettings.maxCompanionCount;
	const isAddButtonDisabled = companions.length >= section.section.companionSectionSettings.maxCompanionCount;
	const addTitle = isAddButtonDisabled ? t("Maximum amount of companions already added") : t("Add companion");

	return (
		<>
			<SectionWrapper color={section.section.backgroundColor}>
				<Header>
					<Heading>
						<H4>{t("Companions")}</H4>
						<Count>({t("{{ currentCount }} of {{ maxCount }}", { currentCount, maxCount })})</Count>
					</Heading>
					<Button
						title={addTitle}
						disabled={isAddButtonDisabled}
						type="button"
						onClick={onNewClick}
						prefix={<Icon icon="plus" title={addTitle} />}
					>
						{t("Add companion")}
					</Button>
				</Header>
				{!companions.length && <Empty>{t("No companions")}</Empty>}
				{companions.map((companion, index) => {
					const isEditButtonDisabled = companion.isEditable;
					const editTitle = isEditButtonDisabled ? t("Companion is already registered") : t("Edit companion");

					return (
						<Card key={index} $color={section.section.backgroundColor === "background" ? "background" : "content"}>
							<CardHeader>
								<CardHeading>{`${index + 1}. ${t("Companion")}`}</CardHeading>
								<CardHeaderActions>
									<IconButtonWithTitle
										size="small"
										onClick={() => setCurrentCompanion({ index, companion })}
										type="button"
										disabled={isEditButtonDisabled}
										title={editTitle}
										icon="edit"
									/>
									<IconButton
										size="small"
										onClick={() => removeCompanion(index)}
										type="button"
										color="danger"
										icon="delete"
										title={t("Remove companion")}
									/>
								</CardHeaderActions>
							</CardHeader>
							<CompanionInfo companion={companion} dataFields={dataFields} />
						</Card>
					);
				})}
			</SectionWrapper>
			{currentCompanion && (
				<RegistrationFormCompanionDrawer
					stepId={stepId}
					section={section}
					currentCompanion={currentCompanion}
					onClose={() => setCurrentCompanion(null)}
					currentStepValues={values}
				/>
			)}
		</>
	);
};

export const CompanionInfo: FC<{ companion: Companion; dataFields: DataField[] }> = ({ companion, dataFields }) => {
	return (
		<StyledCardBody>
			{dataFields.map((dataField) => (
				<div key={dataField.id}>
					<DataFieldLabelRenderer>{dataField.label}:</DataFieldLabelRenderer>
					<StyledValue
						dataField={dataField}
						value={companion.dataFieldValues.find((value) => value.id === dataField.id)?.value}
					/>
				</div>
			))}
		</StyledCardBody>
	);
};
