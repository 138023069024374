import { NotContainsOneRuleImpl } from "~root/registration/rules/notContainsOneRule";
import { Rule } from "~shared/types";
import { RuleTypes } from "~shared/types/rules";
import { BaseRule, DataFieldValue } from "./baseRule";
import { ContainsOneRuleImpl } from "./containsOneRule";
import { HasMaxCharsRuleImpl } from "./hasMaxCharsRule";
import { HasMinCharsRuleImpl } from "./hasMinCharsRule";
import { HasNotValueRuleImpl } from "./hasNotValueRule";
import { HasValueRuleImpl } from "./hasValueRule";
import { IsRequiredRuleImpl } from "./isRequiredRule";
import { MaxRuleImpl } from "./maxRule";
import { MinRuleImpl } from "./minRule";
import { MustContainRuleImpl } from "./mustContainRule";
import { MustNotContainRuleImpl } from "./mustNotContainRule";
import { NotUpdatableRuleImpl } from "./notUpdatableRule";
import { ReadOnlyRuleImpl } from "./readOnlyRule";

export const ruleFactory = (rule: Rule, value: DataFieldValue, isNullValid: boolean): BaseRule => {
	switch (rule.type) {
		case RuleTypes.IS_REQUIRED:
			return new IsRequiredRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.NOT_UPDATABLE:
			return new NotUpdatableRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.READ_ONLY:
			return new ReadOnlyRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.HAS_VALUE:
			return new HasValueRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.HAS_NOT_VALUE:
			return new HasNotValueRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.HAS_MAX_CHARS:
			return new HasMaxCharsRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.HAS_MIN_CHARS:
			return new HasMinCharsRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.MUST_CONTAIN:
			return new MustContainRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.MUST_NOT_CONTAIN:
			return new MustNotContainRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.MIN:
			return new MinRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.MAX:
			return new MaxRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.CONTAINS_ONE:
			return new ContainsOneRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
		case RuleTypes.NOT_CONTAINS_RULE:
			return new NotContainsOneRuleImpl(rule.dataField, rule.sectionId, rule.value, value, isNullValid);
	}
};
