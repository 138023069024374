import { FCC, Id, apiIsOK, useAnalytics, useLocale, useToasts } from "@dgs/core";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RegistrationDeclinedAnalyticsEvent } from "~root/analytics/analyticsEvents";
import { getDateFnsLocale } from "~root/locale/getDateFnsLocale";
import { isExistingFile } from "~root/registration/RegistrationFormFileField";
import { registrationFormService } from "~shared/api/registrationForms";
import { useIndexedDb } from "~shared/storage/IndexedDbProvider";
import { Companion, DataFieldValues, Guest, IRegistrationFormStep } from "~shared/types";
import { IRegistrationFormAvailableShowResource, RegistrationStatus } from "~shared/types/registrationForm";
import { useSessionState } from "~shared/utils/useSessionState";
import { FILLED_STEPS } from "../RegistrationFormAvailablePage";
import {
	findNextStep,
	findPrevStep,
	flattenStepDataFields,
	getDataFieldsVisibility,
	getSectionsFromStep,
	getSectionsVisibility,
	getStepsVisibility,
	mapConsentValuesToBody,
	toDataFieldChildrenFromSection,
} from "../registrationFormUtils";
import { ruleFactory } from "../rules/ruleFactory";
import {
	ConsentValues,
	CurrentCompanion,
	DataFieldErrors,
	FilledSteps,
	IRegistrationSubmitValues,
	RegistrationFormContext,
} from "./registrationFormProviderContext";
import {
	toDataField,
	toDataFieldRequestValues,
	toDataFieldTypeValidationErrors,
} from "./registrationFormProviderUtils";

interface RegistrationFormProviderProps {
	registrationForm: IRegistrationFormAvailableShowResource;
	hasFileField: boolean;
	initialFilledSteps: FilledSteps;
}

export const RegistrationFormProvider: FCC<RegistrationFormProviderProps> = ({
	registrationForm,
	initialFilledSteps,
	hasFileField,
	children,
}) => {
	const { track } = useAnalytics();
	const navigate = useNavigate();
	const { store, clearObjectStore } = useIndexedDb();

	const [filledSteps, setFilledSteps] = useState<FilledSteps>(initialFilledSteps);

	const [givenConsents, setGivenConsents] = useSessionState<ConsentValues>("givenConsents", {});
	const [companions, setCompanions] = useSessionState<Companion[]>(
		"companions",
		registrationForm.guest?.companions || [],
	);

	const { locale } = useLocale();
	const dateFnsLocale = useMemo(() => getDateFnsLocale(locale), [locale]);
	const { t } = useTranslation();
	const { showToast } = useToasts();

	const storeGuestFile = useCallback(
		async (value: string | File | null) => {
			if (!hasFileField || !value || isExistingFile(value)) {
				return;
			} else {
				return await store("guest_files", value);
			}
		},
		[hasFileField, store],
	);

	const hiddenSteps = useMemo(
		() => getStepsVisibility(registrationForm.ruleSets, filledSteps),
		[filledSteps, registrationForm.ruleSets],
	);

	const _getHiddenSections = useCallback(
		(filledSteps: FilledSteps) => getSectionsVisibility(registrationForm.steps, registrationForm.ruleSets, filledSteps),
		[registrationForm.ruleSets, registrationForm.steps],
	);

	const _getHiddenDataFields = useCallback(
		(filledSteps: FilledSteps) =>
			getDataFieldsVisibility(registrationForm.steps, registrationForm.ruleSets, filledSteps),
		[registrationForm.ruleSets, registrationForm.steps],
	);

	const isCompanionSectionHidden = useCallback(() => {
		const companionSectionId = registrationForm.steps
			.flatMap(getSectionsFromStep)
			.find((x) => x.entityType === "section" && x.section.type === "companion")?.section.id;
		return _getHiddenSections(filledSteps).includes(companionSectionId);
	}, [_getHiddenSections, filledSteps, registrationForm.steps]);

	const submitStep = useCallback(
		async (stepId: number, values: DataFieldValues) => {
			const newFilledSteps = {
				...filledSteps,
				[stepId]: values,
			};
			const hiddenSteps = getStepsVisibility(registrationForm.ruleSets, newFilledSteps);

			const sessionStorageValues = { ...values };

			for (const dataFieldId of Object.keys(values)) {
				if (values[dataFieldId] instanceof File) {
					sessionStorage.setItem("isActiveSession", "true");
					sessionStorageValues[dataFieldId] = await storeGuestFile(values[dataFieldId]);
				}
			}
			setFilledSteps(newFilledSteps);

			const filledStepsForSessionStorage = {
				...filledSteps,
				[stepId]: sessionStorageValues,
			};
			sessionStorage.setItem(FILLED_STEPS, JSON.stringify(filledStepsForSessionStorage));
			return findNextStep(hiddenSteps, registrationForm.steps, stepId);
		},
		[filledSteps, registrationForm.ruleSets, registrationForm.steps, storeGuestFile],
	);

	const validateStep = useCallback(
		(step: IRegistrationFormStep, values: DataFieldValues) => {
			const allFilledSteps = {
				...filledSteps,
				[step.id]: values,
			};
			const hiddenDataFields = getDataFieldsVisibility([step], registrationForm.ruleSets, allFilledSteps);

			const dataFieldErrors: DataFieldErrors = getSectionsFromStep(step)
				.flatMap(toDataFieldChildrenFromSection)
				.map(toDataField)
				.reduce(toDataFieldTypeValidationErrors(values, t), {});

			const errors: DataFieldErrors = step.children
				.flatMap((x) => {
					if (x.entityType === "section" && x.section.type === "default") {
						return x.section.validationRules
							.map((rule) => {
								return ruleFactory({ ...rule, sectionId: x.section.id }, values[rule.dataField.id], true);
							})
							.filter((rule) => !hiddenDataFields.includes(`${x.section.id}-${rule.dataField.id}`) && !rule.isValid());
					}
					return [];
				})
				.reduce((errors, rule) => {
					const { key, values } = rule.getErrorMessage({ locale: dateFnsLocale });
					return {
						...errors,
						[rule.dataField.id]: t(key as any, values),
					};
				}, {} as DataFieldValues);

			return { ...errors, ...dataFieldErrors };
		},
		[dateFnsLocale, filledSteps, registrationForm, t],
	);

	const clearRegistration = useCallback(async () => {
		if (hasFileField) {
			await clearObjectStore("guest_files");
		}
		setGivenConsents({});
		setFilledSteps([]);
		setCompanions([]);
		sessionStorage.clear();
	}, [clearObjectStore, hasFileField, setCompanions, setGivenConsents]);

	const submitRegistration = useCallback(
		async ({ summaryPageConsents, shopItems, paymentProviderId }: IRegistrationSubmitValues) => {
			const formData = new FormData();
			const dataFieldValues = getDataFieldValuesToSubmit(registrationForm, filledSteps, formData);
			const allConsents = mapConsentValuesToBody(givenConsents).concat(mapConsentValuesToBody(summaryPageConsents));
			const body = {
				dataFieldValues,
				consents: allConsents,
				companions: isCompanionSectionHidden() ? [] : companions,
				shopItems,
				paymentProviderId,
				token: registrationForm.guest?.id,
			};

			formData.append("registration", JSON.stringify(body));

			const response = await registrationFormService.submitRegistrationFormData(registrationForm.id, formData);

			if (apiIsOK(response)) {
				switch (response.data.data.status) {
					case RegistrationStatus.REGISTERED:
					case RegistrationStatus.REGISTERED_WITH_INVOICE:
						navigate("success");
						break;
					case RegistrationStatus.REGISTERED_WITH_PAYMENT_PROVIDER:
						window.location.href = response.data.data.paymentLink;
						break;
					case RegistrationStatus.REGISTERED_WITH_POSITIVE_SCREENING:
					case RegistrationStatus.WAITING_LIST_WITH_POSITIVE_SCREENING:
						navigate("confirmed");
						break;
					case RegistrationStatus.REGISTRATION_CHANGED:
						navigate("edited");
						break;
					case RegistrationStatus.WAITING_LIST:
						if (registrationForm.waitingListConfirmationBlockId) {
							navigate(`waiting-list`);
						} else {
							showToast({
								body: t("You were placed on the waiting list."),
								title: t("Registration"),
								type: "success",
							});
						}
				}

				return true;
			}

			return false;
		},
		[companions, filledSteps, givenConsents, isCompanionSectionHidden, navigate, registrationForm, showToast, t],
	);

	const declineRegistration = useCallback(
		async (dataFields: Record<number, any>, id: Id, guest: Guest | undefined) => {
			const response = await registrationFormService.declineRegistration(
				id,
				Object.keys(dataFields).map((id) => ({ id: id as unknown as number, value: dataFields[id as any] })),
				guest?.id,
			);

			if (apiIsOK(response)) {
				navigate("./declined", { replace: true });
				track(new RegistrationDeclinedAnalyticsEvent(registrationForm.id, new Date()));
			} else {
				showToast({ body: t("An error has occurred"), type: "error", title: t("Declination") });
			}
		},
		[navigate, registrationForm.id, showToast, t, track],
	);

	const previousStep = (currentStepId?: number) => {
		const hiddenSteps = getStepsVisibility(registrationForm.ruleSets, filledSteps);
		return findPrevStep(hiddenSteps, registrationForm.steps, currentStepId ?? null);
	};

	const submitLanding = useCallback(
		(consents: ConsentValues) => {
			const hiddenSteps = getStepsVisibility(registrationForm.ruleSets, filledSteps);
			const newConsents = { ...givenConsents, ...consents };
			setGivenConsents(newConsents);

			return findNextStep(hiddenSteps, registrationForm.steps, null);
		},
		[filledSteps, givenConsents, registrationForm.ruleSets, registrationForm.steps, setGivenConsents],
	);

	const saveCompanion = useCallback(
		(currentCompanion: CurrentCompanion) => {
			const newCompanions = companions.map((c, index) =>
				index === currentCompanion.index ? currentCompanion.companion : c,
			);

			const orderedNewCompanions =
				companions.findIndex((_, index) => currentCompanion.index === index) >= 0
					? newCompanions
					: newCompanions.concat(currentCompanion.companion);

			setCompanions(orderedNewCompanions);
		},
		[companions, setCompanions],
	);

	const removeCompanion = useCallback(
		(indexToRemove: number) => {
			const newCompanions = companions.filter((_, index) => index !== indexToRemove);
			setCompanions(newCompanions);
		},
		[companions, setCompanions],
	);

	return (
		<RegistrationFormContext.Provider
			value={{
				registrationForm,
				previousStep,
				submitStep,
				validateStep,
				submitRegistration,
				submitLanding,
				filledSteps,
				givenConsents,
				companions,
				saveCompanion,
				removeCompanion,
				hiddenSteps,
				getHiddenSections: _getHiddenSections,
				getHiddenDataFields: _getHiddenDataFields,
				isPreview: false,
				clearRegistration,
				declineRegistration,
			}}
		>
			{children}
		</RegistrationFormContext.Provider>
	);
};

const getDataFieldValuesToSubmit = (
	registrationForm: IRegistrationFormAvailableShowResource,
	filledSteps: FilledSteps,
	formData: FormData,
) => {
	const dataFieldValues = flattenStepDataFields(filledSteps);
	const hiddenDataFields = getDataFieldsVisibility(registrationForm.steps, registrationForm.ruleSets, filledSteps);

	return registrationForm.steps
		.flatMap(getSectionsFromStep)
		.filter((section) => section.section.type === "default")
		.flatMap((x) =>
			toDataFieldChildrenFromSection(x).filter((y) => !hiddenDataFields.includes(`${x.section.id}-${y.dataField.id}`)),
		)
		.map(toDataField)
		.map(toDataFieldRequestValues(dataFieldValues, formData));
};
