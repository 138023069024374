import { LinkButton, Loading, Section, SubmitButton } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { registrationFormService } from "~shared/api/registrationForms";
import { ConsentFormFields } from "../consents/ConsentFormFields";
import { ConsentValues, useRegistrationForm } from "../provider/registrationFormProviderContext";
import { findNextStep, toConsentErrors, toConsentValues } from "../registrationFormUtils";
import { RenderRegistrationBlock } from "../RenderRegistrationBlock";

const StyledSection = styled(Section)`
	padding: ${({ theme }) => theme.spacing(4)};
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0 0 ${({ theme }) => theme.spacing(4)} 0;
	gap: ${({ theme }) => theme.spacing(4)};
`;

export const RegistrationLandingPage: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { registrationForm, hiddenSteps, submitLanding, givenConsents } = useRegistrationForm();
	const isRegistered = registrationForm.guest?.isRegistered || false;
	const { data: consents = [], isLoading } = useQuery({
		queryKey: registrationFormService.keys.consentsForLandingPage(registrationForm.id),
		queryFn: () => registrationFormService.consentsForLandingPage(registrationForm.id).then((x) => x.data.data),
	});
	const noLandingBlockAndConsents = registrationForm.landingBlockId === null && !consents.length;

	const onSubmit = useCallback(
		async (values: ConsentValues) => {
			const nextStepId = submitLanding(values);
			navigate(`../${nextStepId}`);
		},
		[navigate, submitLanding],
	);

	if (isLoading) {
		return (
			<StyledSection color="content">
				<Loading />
			</StyledSection>
		);
	}

	if (noLandingBlockAndConsents) {
		const nextStepId = findNextStep(hiddenSteps, registrationForm.steps, null);
		return <Navigate to={`../${nextStepId}`} />;
	}

	return (
		<>
			{registrationForm.landingBlockId && <RenderRegistrationBlock blockId={registrationForm.landingBlockId} />}
			<StyledSection color="content">
				<Formik
					initialValues={toConsentValues(consents, givenConsents)}
					onSubmit={onSubmit}
					validate={(values: ConsentValues) => toConsentErrors(consents, values, t)}
				>
					<Form>
						{consents.length > 0 && <ConsentFormFields consents={consents} />}
						<ButtonWrapper>
							{registrationForm.cancelable && (
								<>
									{isRegistered ? (
										<LinkButton to="../cancel">{t("registration.cancel")}</LinkButton>
									) : (
										<LinkButton to="../decline">{t("registration.decline")}</LinkButton>
									)}
								</>
							)}
							{registrationForm.isReplacementEnabled && registrationForm.guest && (
								<LinkButton to="../nominate-replacement">{t("Nominate replacement")}</LinkButton>
							)}
							<SubmitButton>{t(isRegistered ? "registration.update" : "registration.register")}</SubmitButton>
						</ButtonWrapper>
					</Form>
				</Formik>
			</StyledSection>
		</>
	);
};
