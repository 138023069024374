import { useDynamicConfig } from "@dgs/core";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const ProductTranslationsProvider: FC = () => {
	const { productTranslations } = useDynamicConfig();
	const { i18n } = useTranslation();

	useEffect(() => {
		productTranslations.forEach((productTranslation) => {
			i18n.addResourceBundle(
				productTranslation.locale,
				"translation",
				JSON.parse(productTranslation.value),
				true,
				true,
			);
		});
	}, [i18n, productTranslations]);

	return null;
};
