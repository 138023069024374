import { Id } from "@dgs/core";
import { DataField } from "./datafield";

export enum RuleTypes {
	IS_REQUIRED = "isRequired",
	NOT_UPDATABLE = "notUpdatable",
	READ_ONLY = "readOnly",
	HAS_VALUE = "hasValue",
	HAS_NOT_VALUE = "hasNotValue",
	HAS_MAX_CHARS = "hasMaxChars",
	HAS_MIN_CHARS = "hasMinChars",
	MUST_CONTAIN = "mustContain",
	MUST_NOT_CONTAIN = "mustNotContain",
	MAX = "max",
	MIN = "min",
	CONTAINS_ONE = "containsOne",
	NOT_CONTAINS_RULE = "notContainsOne",
}

interface BaseRule {
	id: number;
	sectionId: Id;
	dataField: DataField;
}

export interface IS_REQUIRED_Rule extends BaseRule {
	value: null;
	type: RuleTypes.IS_REQUIRED;
}

export interface NOT_UPDATABLE_Rule extends BaseRule {
	value: null;
	type: RuleTypes.NOT_UPDATABLE;
}

export interface READ_ONLY_Rule extends BaseRule {
	value: null;
	type: RuleTypes.READ_ONLY;
}

export interface HAS_VALUE_Rule extends BaseRule {
	value: number | string | Date | number[] | boolean;
	type: RuleTypes.HAS_VALUE;
}

export interface HAS_NOT_VALUE_Rule extends BaseRule {
	value: number | string | Date | number[] | boolean;
	type: RuleTypes.HAS_NOT_VALUE;
}

export interface HAS_MAX_CHARS_Rule extends BaseRule {
	value: number;
	type: RuleTypes.HAS_MAX_CHARS;
}

export interface HAS_MIN_CHARS_Rule extends BaseRule {
	value: number;
	type: RuleTypes.HAS_MIN_CHARS;
}

export interface MUST_CONTAIN_Rule extends BaseRule {
	value: number | string | number[] | boolean;
	type: RuleTypes.MUST_CONTAIN;
}

export interface MUST_NOT_CONTAIN_Rule extends BaseRule {
	value: number | string | number[] | boolean;
	type: RuleTypes.MUST_NOT_CONTAIN;
}

export interface MAX_Rule extends BaseRule {
	value: number | string | Date;
	type: RuleTypes.MAX;
}

export interface MIN_Rule extends BaseRule {
	value: number | string | Date;
	type: RuleTypes.MIN;
}

export interface CONTAINS_ONE_Rule extends BaseRule {
	value: number | string;
	type: RuleTypes.CONTAINS_ONE;
}

export interface NOT_CONTAINS_ONE_Rule extends BaseRule {
	value: number | string;
	type: RuleTypes.NOT_CONTAINS_RULE;
}

export type Rule =
	| IS_REQUIRED_Rule
	| NOT_UPDATABLE_Rule
	| READ_ONLY_Rule
	| HAS_VALUE_Rule
	| HAS_NOT_VALUE_Rule
	| HAS_MAX_CHARS_Rule
	| HAS_MIN_CHARS_Rule
	| MUST_CONTAIN_Rule
	| MUST_NOT_CONTAIN_Rule
	| MAX_Rule
	| MIN_Rule
	| CONTAINS_ONE_Rule
	| NOT_CONTAINS_ONE_Rule;
