import { FCC, Id } from "@dgs/core";
import React, { useCallback, useContext, useRef } from "react";

interface IndexedDbContextState {
	store: (store: string, data: any) => Promise<Id>;
	get: (store: string, key: Id) => Promise<any>;
	clearObjectStore: (store: string) => Promise<boolean>;
}

const IndexedDbContext = React.createContext<IndexedDbContextState | undefined>(undefined);

export const IndexedDbProvider: FCC = ({ children }) => {
	const db = useRef<IDBDatabase | null>(null);

	const setupDb = useCallback(async () => {
		return new Promise<IDBDatabase>((resolve, reject) => {
			const request: IDBOpenDBRequest = window.indexedDB.open("registrationDb", 1);

			request.onsuccess = () => {
				resolve(request.result);
			};

			request.onupgradeneeded = (event) => {
				const tempDb = (event.target as any).result;

				tempDb.createObjectStore("guest_files", { autoIncrement: true });
			};

			request.onerror = (e) => {
				reject(e);
			};
		});
	}, []);

	const getDb = useCallback(async () => {
		if (!db.current) {
			db.current = await setupDb();
		}

		return db.current;
	}, [setupDb]);

	const store = useCallback(
		async (store: string, data: any) => {
			const currentDb = await getDb();
			return new Promise<Id>((resolve, reject) => {
				const transaction = currentDb.transaction(store, "readwrite");

				const request = transaction.objectStore(store).add(data);

				transaction.oncomplete = () => {
					resolve(request.result as Id);
				};

				transaction.onerror = (e) => {
					reject(e);
				};
			});
		},
		[getDb],
	);

	const get = useCallback(
		async (store: string, key: Id) => {
			const currentDb = await getDb();
			return new Promise((resolve, reject) => {
				const transaction = currentDb.transaction(store);

				const request = transaction.objectStore(store).get(key);

				transaction.oncomplete = () => {
					resolve(request.result as Id);
				};

				transaction.onerror = (e) => {
					reject(e);
				};
			});
		},
		[getDb],
	);

	const clearObjectStore = useCallback(
		async (store: string) => {
			const currentDb = await getDb();
			return new Promise<boolean>((resolve, reject) => {
				const transaction = currentDb.transaction(store, "readwrite");

				transaction.objectStore(store).clear();

				transaction.oncomplete = () => {
					resolve(true);
				};

				transaction.onerror = (e) => {
					reject(e);
				};
			});
		},
		[getDb],
	);

	return <IndexedDbContext.Provider value={{ store, get, clearObjectStore }}>{children}</IndexedDbContext.Provider>;
};

export const useIndexedDb = () => {
	const context = useContext(IndexedDbContext);

	if (context === undefined) {
		throw new Error(`Context undefined. Are you missing the IndexedDbProvider?`);
	}

	return context;
};
