import { Card, CardBody, CardHeader, Section } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledSection = styled(Section)`
	margin-top: ${({ theme }) => theme.spacing(5)};
	margin-bottom: ${({ theme }) => theme.spacing(5)};
`;
export const PreviewBannerSection: FC = () => {
	const { t } = useTranslation();
	return (
		<StyledSection>
			<Card>
				<CardHeader>{t("Registration Preview")}</CardHeader>
				<CardBody>{t("The preview ignores all conditions and cannot be submitted")}</CardBody>
			</Card>
		</StyledSection>
	);
};
