import { DgsAnalytics, FCC, useAnalytics, useDynamicConfig, useTrackPageView } from "@dgs/core";
import { differenceInSeconds } from "date-fns";
import React, { createContext, useCallback, useContext, useRef } from "react";
import { AdobeAnalytics } from "~root/analytics/AdobeAnalytics";
import {
	AllAnalyticsEvents,
	RegistrationAbortedAnalyticsEvent,
	RegistrationCompletedAnalyticsEvent,
	RegistrationShopStepAbortedAnalyticsEvent,
	RegistrationShopStepCompletedAnalyticsEvent,
	RegistrationShopStepStartedAnalyticsEvent,
	RegistrationStartedAnalyticsEvent,
	RegistrationStepAbortedAnalyticsEvent,
	RegistrationStepCompletedAnalyticsEvent,
	RegistrationStepStartedAnalyticsEvent,
	RegistrationSummaryAbortedAnalyticsEvent,
	RegistrationSummaryCompletedAnalyticsEvent,
	RegistrationSummaryStartedAnalyticsEvent,
} from "~root/analytics/analyticsEvents";

interface PMAnalyticsState {
	trackRegistrationStarted: (registrationId: string, currentStepId: number) => void;
	trackRegistrationCompleted: (registrationId: string) => void;
	trackRegistrationAborted: (registrationId: string, currentStepId: number) => void;
	trackNextStep: (registrationId: string, currentStepId: number, nextStepId: number) => void;
	trackPreviousStep: (registrationId: string, currentStepId: number, nextStepId: number) => void;

	trackSummaryStarted: (registrationId: string, currentStepId: number) => void;
	trackSummaryAborted: (registrationId: string, previousStepId: number) => void;
	trackSummaryAbortedWithShop: (registrationId: string) => void;

	trackShopStarted: (registrationId: string, stepId: number) => void;
	trackShopCompleted: (registrationId: string) => void;
	trackShopAborted: (registrationId: string, stepId: number) => void;
}

const payloadMapper = (e: AllAnalyticsEvents) => e.payload;

const PMAnalyticsContext = createContext<PMAnalyticsState | undefined>(undefined);

export const PMAnalyticsProvider: FCC = ({ children }) => {
	const registrationStartedRef = useRef<Date | null>(null);
	const stepStartedRef = useRef<Date | null>(null);
	const { analyticsUrl, productData } = useDynamicConfig();
	const { track } = useAnalytics();
	useTrackPageView();

	const trackRegistrationStarted = useCallback(
		(registrationId: string, stepId: number) => {
			if (registrationStartedRef.current === null) {
				const now = new Date();
				registrationStartedRef.current = now;
				stepStartedRef.current = now;
				track(new RegistrationStartedAnalyticsEvent(registrationId, stepId, now));
				track(new RegistrationStepStartedAnalyticsEvent(registrationId, stepId, now));
			}
		},
		[track],
	);

	const trackRegistrationCompleted = useCallback(
		(registrationId: string) => {
			if (registrationStartedRef.current !== null) {
				const now = new Date();
				track(
					new RegistrationCompletedAnalyticsEvent(
						registrationId,
						now,
						differenceInSeconds(now, registrationStartedRef.current),
					),
				);
				registrationStartedRef.current = null;

				if (stepStartedRef.current) {
					track(
						new RegistrationSummaryCompletedAnalyticsEvent(
							registrationId,
							now,
							differenceInSeconds(now, stepStartedRef.current),
						),
					);
					stepStartedRef.current = null;
				}
			}
		},
		[track],
	);

	const trackRegistrationAborted = useCallback(
		(registrationId: string, currentStepId: number) => {
			if (registrationStartedRef.current !== null) {
				const now = new Date();
				track(
					new RegistrationAbortedAnalyticsEvent(
						registrationId,
						now,
						differenceInSeconds(now, registrationStartedRef.current),
					),
				);
				registrationStartedRef.current = null;

				if (stepStartedRef.current) {
					track(
						new RegistrationStepAbortedAnalyticsEvent(
							currentStepId,
							registrationId,
							now,
							differenceInSeconds(now, stepStartedRef.current),
						),
					);
					stepStartedRef.current = null;
				}
			}
		},
		[track],
	);

	const trackNextStep = useCallback(
		(registrationId: string, currentStepId: number, nextStepId: number) => {
			const now = new Date();
			if (stepStartedRef.current) {
				track(
					new RegistrationStepCompletedAnalyticsEvent(
						currentStepId,
						registrationId,
						now,
						differenceInSeconds(now, stepStartedRef.current),
					),
				);
			}
			stepStartedRef.current = now;
			track(new RegistrationStepStartedAnalyticsEvent(registrationId, nextStepId, now));
		},
		[track],
	);

	const trackPreviousStep = useCallback(
		(registrationId: string, currentStepId: number, previousStepId: number) => {
			const now = new Date();
			if (stepStartedRef.current) {
				track(
					new RegistrationStepAbortedAnalyticsEvent(
						registrationId,
						currentStepId,
						now,
						differenceInSeconds(now, stepStartedRef.current),
					),
				);
			}
			stepStartedRef.current = now;
			track(new RegistrationStepStartedAnalyticsEvent(registrationId, previousStepId, now));
		},
		[track],
	);

	const trackSummaryStarted = useCallback(
		(registrationId: string, currentStepId: number) => {
			const now = new Date();
			if (stepStartedRef.current) {
				track(
					new RegistrationStepCompletedAnalyticsEvent(
						registrationId,
						currentStepId,
						now,
						differenceInSeconds(now, stepStartedRef.current),
					),
				);
			}
			stepStartedRef.current = now;
			track(new RegistrationSummaryStartedAnalyticsEvent(registrationId, now));
		},
		[track],
	);

	const trackSummaryAborted = useCallback(
		(registrationId: string, previousStepId: number) => {
			const now = new Date();
			if (stepStartedRef.current) {
				track(
					new RegistrationSummaryAbortedAnalyticsEvent(
						registrationId,
						now,
						differenceInSeconds(now, stepStartedRef.current),
					),
				);
			}
			stepStartedRef.current = now;
			track(new RegistrationStepStartedAnalyticsEvent(registrationId, previousStepId, now));
		},
		[track],
	);

	const trackShopStarted = useCallback(
		(registrationId: string, currentStepId: number) => {
			const now = new Date();
			if (stepStartedRef.current) {
				track(
					new RegistrationStepCompletedAnalyticsEvent(
						registrationId,
						currentStepId,
						now,
						differenceInSeconds(now, stepStartedRef.current),
					),
				);
			}
			stepStartedRef.current = now;
			track(new RegistrationShopStepStartedAnalyticsEvent(registrationId, now));
		},
		[track],
	);

	const trackShopCompleted = useCallback(
		(registrationId: string) => {
			const now = new Date();
			if (stepStartedRef.current) {
				track(
					new RegistrationShopStepCompletedAnalyticsEvent(
						registrationId,
						now,
						differenceInSeconds(now, stepStartedRef.current),
					),
				);
			}
			stepStartedRef.current = now;
			track(new RegistrationSummaryStartedAnalyticsEvent(registrationId, now));
		},
		[track],
	);

	const trackShopAborted = useCallback(
		(registrationId: string, previousStepId: number) => {
			const now = new Date();
			if (stepStartedRef.current) {
				track(
					new RegistrationShopStepAbortedAnalyticsEvent(
						registrationId,
						now,
						differenceInSeconds(now, stepStartedRef.current),
					),
				);
			}
			stepStartedRef.current = now;
			track(new RegistrationStepStartedAnalyticsEvent(registrationId, previousStepId, now));
		},
		[track],
	);

	const trackSummaryAbortedWithShop = useCallback(
		(registrationId: string) => {
			const now = new Date();
			if (stepStartedRef.current) {
				track(
					new RegistrationSummaryAbortedAnalyticsEvent(
						registrationId,
						now,
						differenceInSeconds(now, stepStartedRef.current),
					),
				);
			}
			stepStartedRef.current = now;
			track(new RegistrationShopStepStartedAnalyticsEvent(registrationId, now));
		},
		[track],
	);

	return (
		<PMAnalyticsContext.Provider
			value={{
				trackRegistrationStarted,
				trackRegistrationAborted,
				trackNextStep,
				trackPreviousStep,
				trackRegistrationCompleted,

				trackSummaryAborted,
				trackSummaryAbortedWithShop,
				trackSummaryStarted,

				trackShopStarted,
				trackShopCompleted,
				trackShopAborted,
			}}
		>
			{productData.enableAnalytics && <DgsAnalytics socketUrl={analyticsUrl} payloadMapper={payloadMapper} />}
			{productData.enableAdobeAnalytics && <AdobeAnalytics />}
			{children}
		</PMAnalyticsContext.Provider>
	);
};

export const usePMAnalytics = () => {
	const ctx = useContext(PMAnalyticsContext);

	if (ctx === undefined) {
		throw new Error("PMAnalyticsContext undefined. Did you forget a provider?");
	}

	return ctx;
};
