import { useDynamicConfig } from "@dgs/core";
import axios from "axios";
import { FC } from "react";

export const PMAxiosConfig: FC = () => {
	const { apiUrl } = useDynamicConfig();

	axios.defaults.baseURL = apiUrl;
	axios.defaults.withCredentials = true;

	return null;
};
