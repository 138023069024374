import React from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationForm } from "../provider/registrationFormProviderContext";
import { RegistrationDefaultEndPage } from "./RegistrationDefaultEndPage";

export const RegistrationReplacedPage = () => {
	const { t } = useTranslation();
	const { registrationForm } = useRegistrationForm();

	return (
		<RegistrationDefaultEndPage
			headline={t("Replacement nomination received")}
			text={t("We will send an invitation to your representative.")}
			blockId={registrationForm.replacedBlockId}
		/>
	);
};
