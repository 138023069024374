import { Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IPaymentTransactionResource } from "~shared/types/paymentTransaction";
import { IShopSettings } from "~shared/types/shopSettings";
import { shopKeys } from "./shopKeys";

export const shopUrls = {
	settings: keyToUrl(shopKeys.settings),
	completeTransaction: (transactionId: Id) => keyToUrl(shopKeys.completeTransaction(transactionId)),
};

const settings = (config?: AxiosRequestConfig) => axios.get<LaravelResponse<IShopSettings>>(shopUrls.settings, config);
const completeTransaction = (id: Id) =>
	axios.get<LaravelResponse<IPaymentTransactionResource>>(shopUrls.completeTransaction(id));
export const shopService = {
	settings,
	completeTransaction,
};
