import { AnyMenuItem, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { menuKeys } from "./menuKeys";

export const menuUrls = {
	index: keyToUrl(menuKeys.index),
};

const index = () => axios.get<LaravelResponse<AnyMenuItem[]>>(menuUrls.index);

export const menuService = {
	index,
};
