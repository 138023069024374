import { H2, IBaseBlockShowResource, RenderView, Section, SubmitButton, TextField, apiIsOK } from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { registrationFormService } from "~shared/api/registrationForms";
import {
	IRegistrationFormAvailableShowResource,
	IRegistrationFormPrivateShowResource,
} from "~shared/types/registrationForm";
import { RegistrationFormAvailablePage } from "./RegistrationFormAvailablePage";

const Header = styled(H2)`
	padding: ${({ theme }) => theme.spacing(5)} 0;
`;

const StyledSection = styled(Section)`
	display: grid;
	justify-content: center;
	align-items: center;
`;

const StyledForm = styled(Form)`
	display: grid;
`;

export const RegistrationFormCodePage: FC<{
	registrationForm: IRegistrationFormPrivateShowResource;
	block: IBaseBlockShowResource | null;
}> = ({ registrationForm, block }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [verifiedRegistrationForm, setVerifiedRegistrationForm] =
		useState<IRegistrationFormAvailableShowResource | null>(null);
	const onSubmit = useCallback(
		async ({ code }: { code: string }) => {
			const res = await registrationFormService.verifyCode(registrationForm.id, code);
			if (apiIsOK(res)) {
				setVerifiedRegistrationForm(res.data.data);
				res.data.data.guest && sessionStorage.setItem("registrationToken", res.data.data.guest.id);
				navigate("landing-page");
			}
		},
		[navigate, registrationForm.id],
	);
	const validate = useCallback(
		async ({ code }: { code: string }) => {
			if (!code) {
				return { code: t("Field is required") };
			}
		},
		[t],
	);

	if (verifiedRegistrationForm) {
		return <RegistrationFormAvailablePage registrationForm={verifiedRegistrationForm} />;
	}

	return (
		<>
			{block && <RenderView block={block} />}
			<StyledSection>
				<Formik initialValues={{ code: "" }} onSubmit={onSubmit} validate={validate}>
					<StyledForm>
						{!block && <Header>{t("Protected registration")}</Header>}
						<TextField name="code" label={t("Please type in your code")} />
						<SubmitButton>{t("registration.submit")}</SubmitButton>
					</StyledForm>
				</Formik>
			</StyledSection>
		</>
	);
};
