import { H5, SlateViewer } from "@dgs/core";
import React, { FC } from "react";
import { IConsent } from "~shared/types";
import { ConsentWrapper } from "./CheckboxConsent";

interface Props {
	consent: IConsent;
}

export const NoticeConsent: FC<Props> = ({ consent }) => {
	return (
		<ConsentWrapper>
			{consent.headline && <H5>{consent.headline}</H5>}
			<SlateViewer content={consent.consent} />
		</ConsentWrapper>
	);
};
