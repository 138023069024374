import { CheckboxControl, useLocale } from "@dgs/core";
import { format, parse, parseISO } from "date-fns";
import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { getDateFnsLocale } from "~root/locale/getDateFnsLocale";
import { DataField, DataFieldOption, DataFieldType } from "~shared/types";
import { getFilenameFromPath, isExistingFile, isNewFile } from "./RegistrationFormFileField";

const noop = () => Promise.resolve();

const StyledSpan = styled.span`
	font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const DataFieldValueRenderer: FC<{
	dataField: DataField;
	value: number | string | number[] | boolean | File | undefined | DataFieldOption[];
	className?: string;
}> = ({ value, dataField }) => {
	const { locale } = useLocale();
	const dateFnsLocale = useMemo(() => getDateFnsLocale(locale), [locale]);

	switch (dataField.type) {
		case DataFieldType.CHECKBOX:
			return <CheckboxControl name={`${dataField.id}`} disabled value={value as boolean} label={""} onChange={noop} />;
		case DataFieldType.SELECT_FIELD: {
			const selectedOption = dataField.options.find((x) => x.id === value)?.value;
			return <Value value={selectedOption} />;
		}
		case DataFieldType.RADIO: {
			const selectedOption = dataField.options.find((x) => x.id === parseInt(value as string))?.value;
			return <Value value={selectedOption} />;
		}
		case DataFieldType.MULTI_SELECT_FIELD:
			return (value as DataFieldOption[]).map((option) => option.value).join(",");
		case DataFieldType.CHECKBOX_GROUP: {
			const selectedOptions = dataField.options
				.filter((x) => (value as number[] | null)?.includes(x.id))
				.map((x) => x.value)
				.join(", ");
			return <Value value={selectedOptions} />;
		}
		case DataFieldType.DATE: {
			if (value) {
				return (
					<Value value={format(parse(value as string, "yyyy-MM-dd", new Date()), "P", { locale: dateFnsLocale })} />
				);
			} else {
				return <Value />;
			}
		}
		case DataFieldType.DATETIME: {
			if (value) {
				return <Value value={value && format(parseISO(value as string), "P HH:mm", { locale: dateFnsLocale })} />;
			} else {
				return <Value />;
			}
		}
		case DataFieldType.CRYPT:
			return <Value value="***********" />;
		case DataFieldType.FILE:
			const myValue = value as File | string | undefined;
			if (!myValue) {
				return <Value />;
			}
			if (isNewFile(myValue)) {
				return <Value value={myValue.name} />;
			}
			if (isExistingFile(myValue)) {
				return <Value value={getFilenameFromPath(myValue)} />;
			}
		default:
			return <Value value={value as number | string} />;
	}
};

interface ValueProps {
	value?: number | string | boolean | number[] | undefined;
	className?: string;
}

const Value: FC<ValueProps> = ({ value, className }) => {
	return <StyledSpan className={className}>{value ? value : "-"}</StyledSpan>;
};
