import { FCC } from "@dgs/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Companion } from "~shared/types/guest";
import { IRegistrationFormAvailableShowResource } from "~shared/types/registrationForm";
import { findNextStep, findPrevStep } from "../registrationFormUtils";
import { ConsentValues, CurrentCompanion, RegistrationFormContext } from "./registrationFormProviderContext";

/*
	Der PreviewRegistrationFormProvider implementiert den RegistrationFormContext mit der Preview Funktionalität, indem
	die Conditions nicht dafür sorgen, dass die Steps/Sections/Data fields ausgeblendet werden.
	Dazu geben die getHidden Funktionen alle hard coded leere array zurück.
	Weiterhin wird die Submit Funktion als Mock implementiert
*/

export const PreviewRegistrationFormProvider: FCC<{ registrationForm: IRegistrationFormAvailableShowResource }> = ({
	registrationForm,
	children,
}) => {
	const [searchParams] = useSearchParams();
	const token = useMemo(
		() => sessionStorage.getItem("registrationToken") || searchParams.get("token") || "",
		[searchParams],
	);
	const [filledSteps, setFilledSteps] = useState<Record<number, Record<string, any>>>({});
	const [givenConsents, setGivenConsents] = useState<ConsentValues>({});
	const [companions, setCompanions] = useState<Companion[]>([]);
	const navigate = useNavigate();

	const submitStep = useCallback(
		async (stepId: number, values: Record<string, any>) => {
			const newFilledSteps = {
				...filledSteps,
				[stepId]: values,
			};
			setFilledSteps(newFilledSteps);

			return findNextStep([], registrationForm.steps, stepId);
		},
		[filledSteps, registrationForm.steps],
	);
	const submitRegistration = () => Promise.resolve(true);
	const declineRegistration = () => {
		navigate("./declined", { replace: true });
		return Promise.resolve();
	};
	const validateStep = () => ({});
	const previousStep = useCallback(
		(currentStepId?: number) => {
			return findPrevStep([], registrationForm.steps, currentStepId ?? null);
		},
		[registrationForm.steps],
	);

	const submitLanding = useCallback(
		(consents: ConsentValues) => {
			setGivenConsents((current) => ({ ...current, ...consents }));
			return findNextStep([], registrationForm.steps, null);
		},
		[registrationForm.steps],
	);

	const saveCompanion = useCallback(
		(currentCompanion: CurrentCompanion) =>
			setCompanions((current) => {
				const newCompanions = current.map((c, index) =>
					index === currentCompanion.index ? currentCompanion.companion : c,
				);

				return !!current.findIndex((_, index) => currentCompanion.index === index)
					? newCompanions
					: newCompanions.concat(currentCompanion.companion);
			}),
		[],
	);

	const removeCompanion = useCallback(
		(indexToRemove: number) => setCompanions((current) => current.filter((_, index) => index !== indexToRemove)),
		[],
	);

	const clearRegistration = useCallback(() => {
		setGivenConsents({});
		setFilledSteps([]);
		setCompanions([]);
	}, [setCompanions, setGivenConsents]);

	useEffect(() => {
		token && token !== sessionStorage.getItem("token") && sessionStorage.setItem("registrationToken", token);
	}, [token]);

	return (
		<RegistrationFormContext.Provider
			value={{
				registrationForm,
				validateStep,
				previousStep,
				submitStep,
				submitRegistration,
				filledSteps,
				givenConsents,
				companions,
				saveCompanion,
				removeCompanion,
				submitLanding,
				hiddenSteps: [],
				getHiddenSections: () => [],
				getHiddenDataFields: () => [],
				isPreview: true,
				clearRegistration,
				declineRegistration,
			}}
		>
			{children}
		</RegistrationFormContext.Provider>
	);
};
