import { Empty } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useRegistrationForm } from "./provider/registrationFormProviderContext";

export const FirstStepRedirect: FC = () => {
	const { registrationForm, hiddenSteps } = useRegistrationForm();
	const steps = registrationForm.steps.filter((x) => !hiddenSteps.includes(x.id));
	const { t } = useTranslation();

	if (!steps.length) {
		return <Empty>{t("No registration steps found")}</Empty>;
	}

	return <Navigate to={`../${steps[0].id}`} />;
};
