import { Loading, RenderView, Section, SubmitButton } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { registrationFormService } from "~shared/api/registrationForms";
import { useRegistrationForm } from "../provider/registrationFormProviderContext";
import { RegistrationFormDataFieldBase } from "../RegistrationFormDataFieldBase";
import { createInitialValues } from "../registrationFormUtils";

const Wrapper = styled(Section)`
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: end;
`;

export const RegistrationDeclinePage = () => {
	const { t } = useTranslation();
	const {
		registrationForm: { id, guest },
		declineRegistration,
	} = useRegistrationForm();
	const { data, isLoading } = useQuery({
		queryKey: registrationFormService.keys.showConfirmDeclination(id),
		queryFn: () => registrationFormService.showConfirmDeclination(id).then((x) => x.data.data),
	});

	if (isLoading || !data) {
		return <Loading />;
	}

	return (
		<>
			{data.block && <RenderView block={data.block} />}
			<Wrapper color="content">
				<Formik
					initialValues={createInitialValues(data.dataFields, guest?.dataFieldValues)}
					onSubmit={(dataFieldValues) => declineRegistration(dataFieldValues, id, guest)}
				>
					<Form>
						{data.dataFields.map((c) => (
							<RegistrationFormDataFieldBase key={c.id} dataField={c} />
						))}
						<ButtonWrapper>
							<SubmitButton>{t("registration.submit")}</SubmitButton>
						</ButtonWrapper>
					</Form>
				</Formik>
			</Wrapper>
		</>
	);
};
