import {
	AnalyticsProvider,
	DynamicConfigProvider,
	FCC,
	RootContainer,
	ToastList,
	ToastProvider,
	defaultRemoteConfigKey,
} from "@dgs/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import { PMAnalyticsProvider } from "~root/analytics/PMAnalyticsProvider";
import { PMBlockProvider } from "~root/blocks/PMBlockProvider";
import { HtmlTitle } from "~root/htmlTitle";
import i18n from "~root/i18n";
import { ProductTranslationsProvider } from "~root/i18n/ProductTranslationsProvider";
import { PMLocaleProvider } from "~root/locale/PMLocaleProvider";
import { PMAxiosConfig } from "~root/PMAxiosConfig";
import { PMAxiosErrorHandler } from "~root/PMAxiosErrorHandler";
import { PMThemeProvider } from "~root/PMThemeProvider";
import { IndexedDbProvider } from "~shared/storage/IndexedDbProvider";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { staleTime: 100000, retry: false },
	},
});

export const AppProviders: FCC = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<DynamicConfigProvider remoteConfigKey={defaultRemoteConfigKey}>
				<PMAxiosConfig />
				<HtmlTitle />
				<HelmetProvider>
					<AnalyticsProvider>
						<PMLocaleProvider>
							<PMAnalyticsProvider>
								<PMThemeProvider>
									<ToastProvider>
										<I18nextProvider i18n={i18n}>
											<ProductTranslationsProvider />
											<PMBlockProvider>
												<IndexedDbProvider>
													<RootContainer>
														<ToastList />
														<PMAxiosErrorHandler />
														{children}
													</RootContainer>
												</IndexedDbProvider>
											</PMBlockProvider>
										</I18nextProvider>
									</ToastProvider>
								</PMThemeProvider>
							</PMAnalyticsProvider>
						</PMLocaleProvider>
					</AnalyticsProvider>
				</HelmetProvider>
			</DynamicConfigProvider>
		</QueryClientProvider>
	);
};
