import { H2 } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ErrorWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Error = styled(H2)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(4)};
	color: ${({ theme }) => theme.colors.palette.grey[60]};
	flex-direction: column;
`;

const Sad = styled.span`
	font-size: 2.5rem;
`;

export const ErrorPage: FC = () => {
	const { t } = useTranslation();
	return (
		<ErrorWrapper>
			<Error>
				<Sad>:(</Sad>
				{t("An error has occurred")}
			</Error>
		</ErrorWrapper>
	);
};
