import { RenderView, Section } from "@dgs/core";
import { useFormikContext } from "formik";
import React, { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { toDataFieldChildrenFromSection } from "~root/registration/registrationFormUtils";
import { IRegistrationFormChildSection, IRegistrationFormCompanionSection, Rule } from "~shared/types";
import { useIsAvailableSection } from "./provider/registrationFormProviderContext";
import { RegistrationCompanionSection } from "./RegistrationCompanionSection";
import { RegistrationDataField } from "./RegistrationDataField";

interface Props {
	sectionOrBlock: IRegistrationFormChildSection;
	stepId: number;
	validationRules: Rule[];
}

const SectionWrapper = styled(Section)`
	gap: ${({ theme }) => theme.spacing(2)};
	padding: ${({ theme }) => theme.spacing(4)};
`;

export const RegistrationSection: FC<Props> = ({ sectionOrBlock, stepId, validationRules }) => {
	const { values, setFieldValue } = useFormikContext<Record<number, any>>();
	const isAvailable = useIsAvailableSection(values, stepId, sectionOrBlock.section.id);
	const validationRuleMap = useMemo(
		() =>
			validationRules.reduce(
				(acc, cur) => ({
					...acc,
					[cur.dataField.id]: [...(acc[cur.dataField.id] ?? []), cur],
				}),
				{} as Record<number, Rule[]>,
			),
		[validationRules],
	);

	useEffect(() => {
		if (!isAvailable && sectionOrBlock.section.type !== "companion") {
			toDataFieldChildrenFromSection(sectionOrBlock).map((dataField) => {
				return setFieldValue(`${dataField.dataField.id}`, undefined);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAvailable, sectionOrBlock]);

	if (!isAvailable) {
		return null;
	}

	if (sectionOrBlock.section.type === "companion") {
		return (
			<RegistrationCompanionSection
				stepId={stepId}
				section={sectionOrBlock as IRegistrationFormChildSection<IRegistrationFormCompanionSection>}
			/>
		);
	}

	return (
		<SectionWrapper color={sectionOrBlock.section.backgroundColor}>
			{sectionOrBlock.section.children.map((dataFieldOrBlock) => {
				if (dataFieldOrBlock.entityType === "block") {
					return (
						<RenderView
							key={`${dataFieldOrBlock.entityType}_${dataFieldOrBlock.block.id}`}
							block={dataFieldOrBlock.block}
						/>
					);
				}

				return (
					<RegistrationDataField
						key={`${dataFieldOrBlock.entityType}_${dataFieldOrBlock.dataField.id}`}
						sectionId={sectionOrBlock.section.id}
						dataField={dataFieldOrBlock.dataField}
						validationRules={validationRuleMap[dataFieldOrBlock.dataField.id] ?? []}
						stepId={stepId}
					/>
				);
			})}
		</SectionWrapper>
	);
};
