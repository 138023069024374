import { useDynamicConfig } from "@dgs/core";
import { FC, useLayoutEffect } from "react";

export const HtmlTitle: FC = () => {
	const { productData } = useDynamicConfig();
	useLayoutEffect(() => {
		document.title = productData.htmlTitle;
	}, [productData]);

	return null;
};
