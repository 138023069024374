import { Card, CardBody, Loading } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { shopKeys } from "~shared/api/shop";
import { shopService } from "~shared/api/shop/shopService";

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const StyledCardBody = styled(CardBody)`
	display: grid;
	grid-auto-rows: 1fr;
	padding: ${({ theme }) => theme.spacing(10)};
	gap: ${({ theme }) => theme.spacing(2)};
`;
export const RegistrationPaymentPendingPage = () => {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const transactionId = searchParams.get("dgsTransactionId") || "";
	const isPaymentLink = searchParams.get("isPaymentLink") || "";
	const { data } = useQuery({
		queryKey: shopKeys.completeTransaction(transactionId),
		enabled: transactionId !== "",
		queryFn: () => shopService.completeTransaction(transactionId).then((r) => r.data.data),
	});

	const completeTransaction = useCallback(async () => {
		if (data) {
			if (data.status === "approved") {
				isPaymentLink === "true" ? navigate("../payment-successful") : navigate("../success");
			} else if (data.status === "open") {
				setTimeout(completeTransaction, 3600);
			} else {
				setSearchParams({ token: data.order.guestId.toString() });
				navigate("../payment-error");
			}
		}
	}, [data, isPaymentLink, navigate, setSearchParams]);

	useEffect(() => {
		void completeTransaction();
	}, [completeTransaction]);

	return (
		<Wrapper>
			<Card>
				<StyledCardBody>
					{t("Your payment is being processed...")}
					<LoadingWrapper>
						<Loading />
					</LoadingWrapper>
				</StyledCardBody>
			</Card>
		</Wrapper>
	);
};
