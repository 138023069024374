import { ActionTypes } from "~shared/types/actions";
import { ConditionAction } from "./baseAction";

export class ShowStepAction extends ConditionAction<number> {
	type = ActionTypes.SHOW_STEP;

	getValue() {
		return this.value;
	}
}
