import { Id } from "@dgs/core";
import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { Companion, DataFieldValues, Guest, IRegistrationFormStep, IShopItemRequest } from "~shared/types";
import { IRegistrationFormAvailableShowResource } from "~shared/types/registrationForm";
export type ConsentValues = Record<string, boolean | string>;
export type ConsentBody = { id: Id; value: boolean };
export type FilledSteps = Record<number, Record<string, any>>;

export type DataFieldErrors = { [key in keyof any]?: string };
export type StepIndex = number | null;

export interface IRegistrationSubmitValues {
	summaryPageConsents: ConsentValues;
	paymentProviderId: Id | null;
	shopItems: IShopItemRequest[] | null;
}

export interface CurrentCompanion {
	index: number;
	companion: Companion;
}

export interface RegistrationFormContextState {
	filledSteps: FilledSteps;
	givenConsents: ConsentValues;
	companions: Companion[];
	saveCompanion: (currentCompanion: CurrentCompanion) => void;
	removeCompanion: (index: number) => void;
	registrationForm: IRegistrationFormAvailableShowResource;
	previousStep: (currentStepId?: number) => StepIndex;
	submitStep: (id: number, values: DataFieldValues) => Promise<StepIndex>;
	validateStep: (step: IRegistrationFormStep, values: Record<string, any>) => Record<string, any>;
	submitRegistration: (submitValues: IRegistrationSubmitValues) => Promise<boolean>;
	submitLanding: (consents: ConsentValues) => number | null;
	hiddenSteps: number[];
	getHiddenSections: (filledSteps: FilledSteps) => number[];
	getHiddenDataFields: (filledSteps: FilledSteps) => string[];
	isPreview: boolean;
	clearRegistration: () => void;
	declineRegistration: (dataFields: Record<number, any>, id: Id, guest: Guest | undefined) => Promise<void>;
}

export const RegistrationFormContext = React.createContext<RegistrationFormContextState | undefined>(undefined);

export const useRegistrationForm = () => {
	const context = useContext(RegistrationFormContext);

	if (context === undefined) {
		throw new Error(`Context undefined. Are you missing the RegistrationFormProvider?`);
	}

	return context;
};

export const useIsAvailableStep = (stepId: number) => {
	const { hiddenSteps, registrationForm } = useRegistrationForm();
	const step = registrationForm.steps.find((x) => x.id === stepId);

	if (!step) {
		return null;
	}
	return { isAvailable: !hiddenSteps.includes(stepId), step };
};

export const useIsAvailableSection = (dataFieldValues: Record<number, any>, stepId: number, sectionId: number) => {
	const { getHiddenSections, filledSteps } = useRegistrationForm();
	return !getHiddenSections({
		...filledSteps,
		[stepId]: dataFieldValues,
	}).includes(sectionId);
};

export const useIsAvailableDataFields = (
	stepId: number,
	sectionId: number,
	dataFieldId: number,
	additionalStepValues: Record<number, any> = {},
) => {
	const { values } = useFormikContext<Record<number, any>>();
	const { getHiddenDataFields, filledSteps } = useRegistrationForm();
	return !getHiddenDataFields({
		...filledSteps,
		[stepId]: { ...additionalStepValues, ...values },
	}).includes(`${sectionId}-${dataFieldId}`);
};
