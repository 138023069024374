import { useLocale } from "@dgs/core";
import React, { FC } from "react";
import styled from "styled-components";
import { IShopItem, IShopSettings } from "~shared/types";
import { ShopItemGross, ShopItemName, Text } from "./RegistrationShopItem";

interface Props {
	shopSettings: IShopSettings;
	shopItem: IShopItem;
	amount: number;
}

const PriceWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content max-content;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(3)};
`;

export const RegistrationSummaryShopItem: FC<Props> = ({ shopSettings, shopItem, amount }) => {
	const { locale } = useLocale();

	return (
		<>
			<ShopItemName shopItem={shopItem} />
			<Text>
				{(shopItem.prices.net / 100).toLocaleString(locale, {
					style: "currency",
					currency: shopSettings.currency.isoCode,
				})}
			</Text>
			<PriceWrapper>
				<ShopItemGross shopItem={shopItem} shopSettings={shopSettings} />
				<span>x</span>
				<span>{amount}</span>
			</PriceWrapper>
			{shopItem.depositInPercent && <Text>{`${shopItem.depositInPercent}%`}</Text>}
			<Text>
				{((shopItem.prices.gross / 100) * amount).toLocaleString(locale, {
					style: "currency",
					currency: shopSettings.currency.isoCode,
				})}
			</Text>
		</>
	);
};
