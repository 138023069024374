import { Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IConsent, IPaymentProvider, IShopItem, IShopItemRequest } from "~shared/types";
import { Companion, DataFieldValue } from "~shared/types/guest";
import {
	IConfirmResource,
	IRegistrationFormAvailableShowResource,
	IRegistrationFormShowResource,
	RegistrationForm,
	RegistrationResponse,
} from "~shared/types/registrationForm";

export class RegistrationFormService {
	public keys = {
		details: (id: Id) => ["registration-forms", `${id}`],
		previewDetails: (id: Id) => ["registration-forms", "preview", `${id}`],
		cancel: (id: Id) => ["registration-forms", `${id}`, "cancel"],
		decline: (id: Id) => ["registration-forms", `${id}`, "decline"],
		consentsForLandingPage: (id: Id) => ["registration-forms", `${id}`, "consents", "landing-page"],
		consentsForSummaryPage: (id: Id) => ["registration-forms", `${id}`, "consents", "summary-page"],
		shopItems: (id: Id) => ["registration-forms", `${id}`, "shop", "items"],
		paymentProviders: (id: Id) => ["registration-forms", `${id}`, "shop", "payment-providers"],
		showConfirmDeclination: (id: Id) => ["registration-forms", `${id}`, "confirm-declination"],
		showConfirmCancellation: (id: Id) => ["registration-forms", `${id}`, "confirm-cancellation"],
		nominateReplacement: (id: Id) => ["registration-forms", `${id}`, "nominate-replacement"],
		showConfirmReplacement: (id: Id) => ["registration-forms", `${id}`, "confirm-replacement"],
		verifyCode: (id: Id) => ["registration-forms", `${id}`, "verify-code"],
	};
	protected urls = {
		details: (id: Id) => keyToUrl(this.keys.details(id)),
		previewDetails: (id: Id) => keyToUrl(this.keys.previewDetails(id)),
		consentForLandingPage: (formId: Id) => keyToUrl(this.keys.consentsForLandingPage(formId)),
		consentForSummaryPage: (formId: Id) => keyToUrl(this.keys.consentsForSummaryPage(formId)),
		cancel: (formId: Id) => keyToUrl(this.keys.cancel(formId)),
		decline: (formId: Id) => keyToUrl(this.keys.decline(formId)),
		shopItems: (id: Id) => keyToUrl(this.keys.shopItems(id)),
		paymentProviders: (formId: Id) => keyToUrl(this.keys.paymentProviders(formId)),
		showConfirmDeclination: (formId: Id) => keyToUrl(this.keys.showConfirmDeclination(formId)),
		showConfirmCancellation: (formId: Id) => keyToUrl(this.keys.showConfirmCancellation(formId)),
		nominateReplacement: (formId: Id) => keyToUrl(this.keys.nominateReplacement(formId)),
		showConfirmReplacement: (formId: Id) => keyToUrl(this.keys.showConfirmReplacement(formId)),
		verifyCode: (formId: Id) => keyToUrl(this.keys.verifyCode(formId)),
	};

	show = (id: Id, token: string | null) =>
		axios.get<LaravelResponse<IRegistrationFormShowResource>>(this.urls.details(id), {
			params: {
				token,
			},
		});

	previewShow = (id: Id) => {
		return axios.get<LaravelResponse<IRegistrationFormAvailableShowResource>>(this.urls.previewDetails(id));
	};

	consentsForLandingPage = (id: Id, emailDomain?: string) => {
		return axios.get<LaravelResponse<IConsent[]>>(this.urls.consentForLandingPage(id), { params: { emailDomain } });
	};

	consentsForSummaryPage = (id: Id, emailDomain?: string) => {
		return axios.get<LaravelResponse<IConsent[]>>(this.urls.consentForSummaryPage(id), { params: { emailDomain } });
	};

	getPaymentProviders = (formId: Id) =>
		axios.get<LaravelResponse<IPaymentProvider[]>>(this.urls.paymentProviders(formId));

	showConfirmDeclination = (formId: Id) =>
		axios.get<LaravelResponse<IConfirmResource>>(this.urls.showConfirmDeclination(formId));

	showConfirmCancellation = (formId: Id) =>
		axios.get<LaravelResponse<IConfirmResource>>(this.urls.showConfirmCancellation(formId));

	showConfirmReplacement = (formId: Id) =>
		axios.get<LaravelResponse<IConfirmResource>>(this.urls.showConfirmReplacement(formId));

	getShopItems = (formId: Id, countryId: Id, isBusiness: boolean | null) =>
		axios.get<LaravelResponse<IShopItem[]>>(this.urls.shopItems(formId), {
			params: { countryId, isBusiness: isBusiness ? 1 : 0 },
		});

	cancelRegistration = (formId: Id, dataFieldValues: { id: number; value: any }[], token?: string) =>
		axios.post<LaravelResponse<RegistrationForm>>(this.urls.cancel(formId), { dataFieldValues, token });

	declineRegistration = (formId: Id, dataFieldValues: { id: number; value: any }[], token?: string) =>
		axios.post<LaravelResponse<RegistrationForm>>(this.urls.decline(formId), { dataFieldValues, token });

	nominateReplacement = (formId: Id, dataFieldValues: { id: number; value: any }[], token?: string) =>
		axios.post<LaravelResponse<RegistrationForm>>(this.urls.nominateReplacement(formId), { dataFieldValues, token });

	verifyCode = (formId: Id, registrationCode: string) =>
		axios.post<LaravelResponse<IRegistrationFormAvailableShowResource>>(this.urls.verifyCode(formId), {
			registrationCode,
		});

	submitRegistration = (
		formId: Id,
		dataFieldValues: DataFieldValue[],
		consents: { id: Id; value: boolean }[],
		companions: Companion[],
		shopItems: IShopItemRequest[] | null,
		paymentProviderId: Id | null,
		token?: string,
	) =>
		axios.post<LaravelResponse<RegistrationResponse>>(this.urls.details(formId), {
			token,
			dataFieldValues,
			consents,
			companions,
			shopItems,
			paymentProviderId,
		});

	submitRegistrationFormData = (formId: Id, data: FormData) =>
		axios.post<LaravelResponse<RegistrationResponse>>(this.urls.details(formId), data, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
}

export const registrationFormService = new RegistrationFormService();
