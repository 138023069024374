import * as Sentry from "@sentry/react";
import React, { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppProviders } from "~root/AppProviders";
import { BlockPage } from "~root/blocks/BlocksPage";
import { Content } from "~root/content";
import { InitiateRegistrationPage } from "~root/InitiateRegistrationPage";
import { RegistrationPage } from "./registration/RegistrationPage";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
export const AppRoutes: FC = () => {
	return (
		<BrowserRouter>
			<AppProviders>
				<SentryRoutes>
					<Route path="/*" element={<Content />}>
						<Route path="blocks/:slug" element={<BlockPage />} />
						<Route path="initiate-registration/:guestId" element={<InitiateRegistrationPage />} />
						<Route path="registrations/*" element={<RegistrationPage />} />
					</Route>
				</SentryRoutes>
			</AppProviders>
		</BrowserRouter>
	);
};
