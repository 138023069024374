import { IRuleSet } from "~shared/types";
import { ActionTypes } from "~shared/types/actions";
import { actionFactory } from "../actions/actionFactory";
import { BaseAction, ConditionAction } from "../actions/baseAction";
import { BaseRule } from "../rules";
import { ruleFactory } from "../rules/ruleFactory";

export interface RuleStepResult {
	id: number;
	show: boolean;
}

export type RuleSectionResult = RuleStepResult;

export interface RuleDataFieldResult {
	id: string;
	show: boolean;
}

export type RuleResult = RuleStepResult | RuleSectionResult | RuleDataFieldResult;

export class RuleSet {
	public rules: BaseRule[];
	public actions: BaseAction<any>[];
	public isAndLinked: boolean;

	constructor(ruleSet: IRuleSet, values: Record<number, any>) {
		this.rules = ruleSet.rules.map((x) => ruleFactory(x, values[x.dataField.id], false));
		this.actions = ruleSet.actions.map((x) => actionFactory(x));
		this.isAndLinked = ruleSet.isAndLinked;
	}

	public areAllRulesValid(): boolean {
		return this.isAndLinked ? this.rules.every((x) => x.isValid()) : this.rules.some((x) => x.isValid());
	}

	public getHiddenSteps(): RuleStepResult[] {
		return this.getHiddenByType(ActionTypes.SHOW_STEP) as RuleStepResult[];
	}

	public getHiddenSections(): RuleSectionResult[] {
		return this.getHiddenByType(ActionTypes.SHOW_SECTION) as RuleSectionResult[];
	}

	public getHiddenDataFields(): RuleDataFieldResult[] {
		return this.getHiddenByType(ActionTypes.SHOW_DATA_FIELD) as RuleDataFieldResult[];
	}

	private getHiddenByType(type: ActionTypes): RuleResult[] {
		return this.actions
			.filter((x) => x.type === type)
			.reduce((hidden, action) => {
				const id = (action as ConditionAction).getValue() as any;
				return hidden.concat({ id, show: this.areAllRulesValid() });
			}, [] as RuleResult[]);
	}
}
