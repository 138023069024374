import React from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationForm } from "~root/registration/provider/registrationFormProviderContext";
import { RegistrationDefaultEndPage } from "./RegistrationDefaultEndPage";

export const RegistrationSuccessPage = () => {
	const { t } = useTranslation();
	const { registrationForm } = useRegistrationForm();

	return (
		<RegistrationDefaultEndPage
			headline={t("Registration confirmed")}
			text={t("Your registration was successful.")}
			blockId={registrationForm.successBlockId}
		/>
	);
};
