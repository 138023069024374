import { AnyMenuItem } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { menuKeys } from "./menuKeys";
import { menuService } from "./menuService";

const initialItems: AnyMenuItem[] = [];

const menuFetcher = () => menuService.index().then((r) => r.data.data);
export const useMenuQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: menuKeys.index,
		queryFn: menuFetcher,
	});

	return {
		items: data || initialItems,
		isLoading,
	};
};
