import { IBaseBlockShowResource, Id } from "@dgs/core";
import { DataField } from "~shared/types/datafield";
import { Guest } from "./guest";
import { Rule } from "./rules";
import { IRuleSet } from "./ruleSet";
import { IRegistrationFormStep } from "./step";

export interface RegistrationForm {
	id: string;
	name: string;
	steps: IRegistrationFormStep[];
	declinationFormDataFields: DataField[];
	cancellationFormDataFields: DataField[];

	ruleSets: IRuleSet[];
	validationRules: Rule[];
	registeredMailId: number | null;
	editedMailId: number | null;
	declinedMailId: number | null;
	cancelledMailId: number | null;

	closedBlockId: number | null;
	successBlockId: number | null;
	editedBlockId: number | null;
	declinedBlockId: number | null;
	cancelledBlockId: number | null;

	confirmDeclinationBlock: IBaseBlockShowResource | null;
	confirmCancellationBlock: IBaseBlockShowResource | null;

	closeAt: Date | null;
	isCancelable: boolean;
	isEditable: boolean;
	visibility: RegistrationFormVisibility;

	landingBlockId: Id | null;
	summaryBlockId: Id | null;

	guest?: Guest;

	cancelable: boolean;
	isShopEnabled: boolean;

	waitingListConfirmationBlockId: number | null;
}

export enum RegistrationStatus {
	REGISTERED = "STATUS1",
	REGISTERED_WITH_INVOICE = "STATUS2",
	REGISTERED_WITH_PAYMENT_PROVIDER = "STATUS3",
	REGISTERED_WITH_POSITIVE_SCREENING = "STATUS4",
	REGISTRATION_CHANGED = "STATUS5",
	WAITING_LIST = "STATUS6",
	WAITING_LIST_WITH_POSITIVE_SCREENING = "STATUS7",
}

interface PaymentRegistrationResponse {
	paymentLink: string;
	status: RegistrationStatus.REGISTERED_WITH_PAYMENT_PROVIDER;
}
interface OtherRegistrationResponse {
	paymentLink: null;
	status: Exclude<RegistrationStatus, RegistrationStatus.REGISTERED_WITH_PAYMENT_PROVIDER>;
}

export type RegistrationResponse = PaymentRegistrationResponse | OtherRegistrationResponse;

export interface IRegistrationFormPrivateShowResource {
	id: Id;
	privateBlock: IBaseBlockShowResource | null;
	type: "PRIVATE";
	isCodePageEnabled: boolean;
	codePageBlock: IBaseBlockShowResource | null;
}

export interface IRegistrationFormClosedShowResource {
	id: Id;
	closedBlock: IBaseBlockShowResource | null;
	type: "CLOSED";
}

export interface IRegistrationFormAvailableShowResource {
	type: "FORM";
	id: Id;
	steps: IRegistrationFormStep[];
	ruleSets: IRuleSet[];
	guest?: Guest;
	cancelable: boolean;
	isShopEnabled: boolean;
	isReplacementEnabled: boolean;

	landingBlockId: Id | null;
	summaryBlockId: Id | null;

	closedBlockId: number | null;
	successBlockId: number | null;
	editedBlockId: number | null;
	declinedBlockId: number | null;
	cancelledBlockId: number | null;
	replacedBlockId: number | null;
	waitingListConfirmationBlockId: number | null;
	screeningStatusPositiveBlockId: number | null;
	paymentSuccessfulBlockId: number | null;
	visibility: RegistrationFormVisibility;
}

export interface IConfirmResource {
	block: IBaseBlockShowResource | null;
	dataFields: DataField[];
}

export type IRegistrationFormShowResource =
	| IRegistrationFormPrivateShowResource
	| IRegistrationFormAvailableShowResource
	| IRegistrationFormClosedShowResource;

export enum RegistrationFormVisibility {
	ALL = "all",
	PRIVATE = "private",
	PUBLIC = "public",
	CLOSED = "closed",
}

export const isRegistrationFormClosed = (
	registrationForm: IRegistrationFormShowResource,
): registrationForm is IRegistrationFormClosedShowResource => {
	return registrationForm.type === "CLOSED";
};

export const isRegistrationFormPrivate = (
	registrationForm: IRegistrationFormShowResource,
): registrationForm is IRegistrationFormPrivateShowResource => {
	return registrationForm.type === "PRIVATE";
};
