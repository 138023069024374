import { Card, H3 } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CompanionInfo } from "~root/registration/RegistrationCompanionSection";
import { DataField } from "~shared/types";
import { useRegistrationForm } from "../provider/registrationFormProviderContext";

interface Props {
	dataFields: DataField[];
}
export const RegistrationCompanionSummary: FC<Props> = ({ dataFields }) => {
	const { t } = useTranslation();
	const { companions } = useRegistrationForm();
	return (
		<>
			<H3>{t("Companions")}</H3>
			{companions.map((companion, index) => {
				return (
					<Card key={index} $color="content">
						<CompanionInfo companion={companion} dataFields={dataFields} />
					</Card>
				);
			})}
		</>
	);
};
