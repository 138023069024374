import { Loading } from "@dgs/core";
import React, { FC } from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { useRegistrationFormPreviewDetailsQuery } from "~shared/api/registrationForms";
import { PreviewBannerSection } from "./PreviewBannerSection";
import { PreviewRegistrationFormProvider } from "./provider/RegistrationFormPreviewProvider";
import { RegistrationStepView } from "./RegistrationStepView";
import { RegistrationShopPage } from "./shop/RegistrationShopPage";
import { RegistrationShopProvider } from "./shop/RegistrationShopProvider";
import {
	RegistrationDeclinePage,
	RegistrationDeclinedPage,
	RegistrationLandingPage,
	RegistrationSummaryPage,
} from "./staticPages";

export const RegistrationPreviewPage: FC = () => {
	const { registrationId = "" } = useParams<"registrationId">();
	const { data: registrationForm, isLoading } = useRegistrationFormPreviewDetailsQuery(registrationId);

	if (isLoading || !registrationForm) {
		return <Loading />;
	}

	const ShopWrapper = registrationForm.isShopEnabled ? RegistrationShopProvider : React.Fragment;

	return (
		<PreviewRegistrationFormProvider registrationForm={registrationForm}>
			<ShopWrapper>
				<Routes>
					<Route
						element={
							<>
								<PreviewBannerSection />
								<Outlet />
							</>
						}
					>
						<Route index element={<Navigate to="landing-page" />} />
						<Route path="landing-page" element={<RegistrationLandingPage />} />
						<Route path=":stepId" element={<RegistrationStepView />} />
						{registrationForm.isShopEnabled && <Route path="shop" element={<RegistrationShopPage />} />}
						<Route path="summary" element={<RegistrationSummaryPage />} />
						<Route path="decline" element={<RegistrationDeclinePage />} />
						<Route path="declined" element={<RegistrationDeclinedPage />} />
					</Route>
				</Routes>
			</ShopWrapper>
		</PreviewRegistrationFormProvider>
	);
};
