import { Id, Loading, RenderView, usePublicBlockQueryShowById } from "@dgs/core";
import React, { FC } from "react";

interface Props {
	blockId: Id | null;
}

export const RenderRegistrationBlock: FC<Props> = ({ blockId }) => {
	if (blockId === null) {
		return null;
	}

	return <RenderBlock blockId={blockId} />;
};
export const RenderBlock: FC<{ blockId: Id }> = ({ blockId }) => {
	const { block, isLoading } = usePublicBlockQueryShowById(blockId);

	if (isLoading) {
		return <Loading />;
	}
	return block && <RenderView block={block} />;
};
