import coreDa from "@dgs/core/src/i18n/translations/da.json";
import coreDe from "@dgs/core/src/i18n/translations/de.json";
import coreEn from "@dgs/core/src/i18n/translations/en.json";
import coreEs from "@dgs/core/src/i18n/translations/es.json";
import coreFi from "@dgs/core/src/i18n/translations/fi.json";
import coreFr from "@dgs/core/src/i18n/translations/fr.json";
import coreIt from "@dgs/core/src/i18n/translations/it.json";
import coreNb from "@dgs/core/src/i18n/translations/nb.json";
import coreNl from "@dgs/core/src/i18n/translations/nl.json";
import corePl from "@dgs/core/src/i18n/translations/pl.json";
import corePt from "@dgs/core/src/i18n/translations/pt-PT.json";
import coreSv from "@dgs/core/src/i18n/translations/sv.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import da from "./da.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fi from "./fi.json";
import fr from "./fr.json";
import it from "./it.json";
import nl from "./nl.json";
import no from "./no.json";
import pl from "./pl.json";
import pt from "./pt.json";
import sv from "./sv.json";

export const resources = {
	en: { translation: { ...coreEn, ...en } },
	de: { translation: { ...coreDe, ...de } },
	es: { translation: { ...coreEs, ...es } },
	fr: { translation: { ...coreFr, ...fr } },
	pt: { translation: { ...corePt, ...pt } },
	it: { translation: { ...coreIt, ...it } },
	da: { translation: { ...coreDa, ...da } },
	nl: { translation: { ...coreNl, ...nl } },
	pl: { translation: { ...corePl, ...pl } },
	sv: { translation: { ...coreSv, ...sv } },
	fi: { translation: { ...coreFi, ...fi } },
	no: { translation: { ...coreNb, ...no } },
} as const;

i18n.use(initReactI18next).init({
	resources,
	lng: "de",
	keySeparator: false, // we do not use keys in form messages.welcome
	// saveMissing: true,
	// missingKeyHandler: (_, __, key) => console.log(key),
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
});

export default i18n;
