import { H2, IBaseBlockShowResource, RenderView, Section } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Header = styled(H2)`
	padding: ${({ theme }) => theme.spacing(5)} 0;
`;

const StyledSection = styled(Section)`
	display: grid;
	justify-content: center;
	align-items: center;
`;

export const RegistrationClosedPage: FC<{ block: IBaseBlockShowResource | null }> = ({ block }) => {
	const { t } = useTranslation();
	if (block) {
		return <RenderView block={block} />;
	}

	return (
		<StyledSection>
			<Header>{t("Closed registration")}</Header>
			<p>{t("This registration is closed.")}</p>
		</StyledSection>
	);
};
