import { FileInputField, FormLabel, IconAnchorButton, IconButton, useDynamicConfig } from "@dgs/core";
import { ErrorMessage, useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { config } from "~root/config";
import { useRegistrationForm } from "./provider/registrationFormProviderContext";

const StyledFormLabel = styled(FormLabel)`
	display: block;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
const Wrapper = styled.div`
	padding-bottom: 1.5rem;
`;
const ButtonWrapper = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing(1)};
`;

const Error = styled.div`
	font-size: 0.875rem;
	color: ${({ theme }) => theme.colors.palette.danger.main.base};
`;

export const getFilenameFromPath = (path: string) => {
	const res = path.split("/");
	return res[res.length - 1];
};
export const isNewFile = (value: string | File): value is File => {
	return value instanceof File;
};
export const isExistingFile = (value: string | File): value is string => {
	return typeof value === "string";
};
interface Props {
	dataFieldId: number;
	label: string;
	name: string;
	required?: boolean;
	readOnly?: boolean;
	description?: string;
}
export const RegistrationFormFileField: FC<Props> = (props) => {
	const { registrationForm } = useRegistrationForm();
	const {
		dataFieldSettings: { allowedMimeTypes },
	} = useDynamicConfig();
	const [{ value }, , { setValue }] = useField<string | File | null>(props.name);
	const { t } = useTranslation();
	const { apiUrl } = useDynamicConfig();

	if (!value) {
		return (
			<FileInputField
				{...props}
				description={
					props.description ??
					t("Max. {{size}} MB file size", { size: config.uploadedFileMaxSize }) +
						", " +
						t("Allowed file types: image, video, PDF, office (word/excel etc.), font")
				}
				accept={allowedMimeTypes.join(", ")}
				disabled={props.readOnly}
			/>
		);
	}

	return (
		<Wrapper>
			<StyledFormLabel>{props.label}</StyledFormLabel>
			<ButtonWrapper>
				{isNewFile(value) ? value.name : getFilenameFromPath(value)}
				{isExistingFile(value) && (
					<IconAnchorButton
						href={`${apiUrl}/registration-forms/${registrationForm.id}/data-fields/${props.dataFieldId}/download?token=${registrationForm.guest?.id}`}
						type="button"
						size="small"
						target="_blank"
						icon="file_download"
						title={t("Download file")}
					/>
				)}
				{!props.readOnly && (
					<IconButton title={t("Remove file")} type="button" size="small" onClick={() => setValue(null)} icon="close" />
				)}
			</ButtonWrapper>
			<Error>
				<ErrorMessage name={props.name} />
			</Error>
		</Wrapper>
	);
};
