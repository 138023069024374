import { RuleTypes } from "~shared/types";
import { BaseRule, TranslatedError } from "./baseRule";

export class NotContainsOneRuleImpl extends BaseRule {
	type: RuleTypes = RuleTypes.CONTAINS_ONE;

	isValid(): boolean {
		if (this.value !== null) {
			const value = this.value as string;
			const ruleValues = this.ruleValue as string[];
			return !ruleValues.some((ruleValue) => value.includes(ruleValue));
		}
		return true;
	}

	getErrorMessage(): TranslatedError {
		const key = "Field cannot contain one of the following values: {{value}}";
		const values = { label: this.dataField.label, value: "" };
		const value = this.ruleValue as string[];
		values.value = value.join(", ");

		return {
			key,
			values,
		};
	}
}
