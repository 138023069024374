import { H2, Id, Section } from "@dgs/core";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { useRegistrationForm } from "~root/registration/provider/registrationFormProviderContext";
import { RenderBlock } from "~root/registration/RenderRegistrationBlock";

const Header = styled(H2)`
	padding: ${({ theme }) => theme.spacing(5)} 0;
`;

const StyledSection = styled(Section)`
	display: grid;
	justify-content: center;
	align-items: center;
`;

interface Props {
	blockId: Id | null;
	headline: string;
	text: string;
}

export const RegistrationDefaultEndPage: FC<Props> = ({ blockId, headline, text }) => {
	const { clearRegistration } = useRegistrationForm();

	useEffect(() => {
		clearRegistration();
	}, [clearRegistration]);

	if (blockId === null) {
		return (
			<StyledSection>
				<Header>{headline}</Header>
				<p>{text}</p>
			</StyledSection>
		);
	}

	return <RenderBlock blockId={blockId} />;
};
