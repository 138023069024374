import { AnalyticsEvent, Id, PageViewAnalyticsEvent } from "@dgs/core";

export class RegistrationStartedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_PROCESS_STARTED"> {
	type = "REGISTRATION_PROCESS_STARTED" as const;

	constructor(registrationId: Id, stepId: Id, time: Date) {
		super({ registrationId, stepId, time: time.toISOString() });
	}
}

export class RegistrationCompletedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_PROCESS_COMPLETED"> {
	type = "REGISTRATION_PROCESS_COMPLETED" as const;

	constructor(registrationId: Id, time: Date, duration: number) {
		super({ registrationId, time: time.toISOString(), duration });
	}
}

export class RegistrationAbortedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_PROCESS_ABORTED"> {
	type = "REGISTRATION_PROCESS_ABORTED" as const;

	constructor(registrationId: Id, time: Date, duration: number) {
		super({ registrationId, time: time.toISOString(), duration });
	}
}

export class RegistrationShopStepStartedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_SHOP_STEP_STARTED"> {
	type = "REGISTRATION_SHOP_STEP_STARTED" as const;

	constructor(registrationId: Id, time: Date) {
		super({ registrationId, time: time.toISOString() });
	}
}

export class RegistrationShopStepCompletedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_SHOP_STEP_COMPLETED"> {
	type = "REGISTRATION_SHOP_STEP_COMPLETED" as const;

	constructor(registrationId: Id, time: Date, duration: number) {
		super({ registrationId, time: time.toISOString(), duration });
	}
}

export class RegistrationShopStepAbortedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_SHOP_STEP_ABORTED"> {
	type = "REGISTRATION_SHOP_STEP_ABORTED" as const;

	constructor(registrationId: Id, time: Date, duration: number) {
		super({ registrationId, time: time.toISOString(), duration });
	}
}

export class RegistrationStepStartedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_STEP_STARTED"> {
	type = "REGISTRATION_STEP_STARTED" as const;

	constructor(registrationId: Id, stepId: Id, time: Date) {
		super({ registrationId, stepId, time: time.toISOString() });
	}
}

export class RegistrationStepCompletedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_STEP_COMPLETED"> {
	type = "REGISTRATION_STEP_COMPLETED" as const;

	constructor(registrationId: Id, stepId: Id, time: Date, duration: number) {
		super({ registrationId, stepId, time: time.toISOString(), duration });
	}
}

export class RegistrationStepAbortedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_STEP_ABORTED"> {
	type = "REGISTRATION_STEP_ABORTED" as const;

	constructor(registrationId: Id, stepId: Id, time: Date, duration: number) {
		super({ registrationId, stepId, time: time.toISOString(), duration });
	}
}

export class RegistrationSummaryStartedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_SUMMARY_STARTED"> {
	type = "REGISTRATION_SUMMARY_STARTED" as const;

	constructor(registrationId: Id, time: Date) {
		super({ registrationId, time: time.toISOString() });
	}
}

export class RegistrationSummaryCompletedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_SUMMARY_COMPLETED"> {
	type = "REGISTRATION_SUMMARY_COMPLETED" as const;

	constructor(registrationId: Id, time: Date, duration: number) {
		super({ registrationId, time: time.toISOString(), duration });
	}
}

export class RegistrationSummaryAbortedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_SUMMARY_ABORTED"> {
	type = "REGISTRATION_SUMMARY_ABORTED" as const;

	constructor(registrationId: Id, time: Date, duration: number) {
		super({ registrationId, time: time.toISOString(), duration });
	}
}

export class RegistrationDeclinedAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_DECLINED"> {
	type = "REGISTRATION_DECLINED" as const;

	constructor(registrationId: Id, time: Date) {
		super({ registrationId, time: time.toISOString() });
	}
}

export class RegistrationCanceledAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_CANCELED"> {
	type = "REGISTRATION_CANCELED" as const;

	constructor(registrationId: Id, time: Date) {
		super({ registrationId, time: time.toISOString() });
	}
}

export class RegistrationReplacementAnalyticsEvent extends AnalyticsEvent<"REGISTRATION_REPLACEMENT"> {
	type = "REGISTRATION_REPLACEMENT" as const;

	constructor(registrationId: Id, time: Date) {
		super({ registrationId, time: time.toISOString() });
	}
}

export type AllAnalyticsEvents =
	| PageViewAnalyticsEvent
	| RegistrationStartedAnalyticsEvent
	| RegistrationCompletedAnalyticsEvent
	| RegistrationAbortedAnalyticsEvent
	| RegistrationShopStepStartedAnalyticsEvent
	| RegistrationShopStepCompletedAnalyticsEvent
	| RegistrationShopStepAbortedAnalyticsEvent
	| RegistrationStepStartedAnalyticsEvent
	| RegistrationStepCompletedAnalyticsEvent
	| RegistrationStepAbortedAnalyticsEvent
	| RegistrationSummaryStartedAnalyticsEvent
	| RegistrationSummaryCompletedAnalyticsEvent
	| RegistrationSummaryAbortedAnalyticsEvent
	| RegistrationDeclinedAnalyticsEvent
	| RegistrationCanceledAnalyticsEvent
	| RegistrationReplacementAnalyticsEvent;
