import { CrudDrawer, RenderView } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationCompanionForm } from "~root/registration/useRegistrationCompanionForm";
import { IRegistrationFormChildSection, IRegistrationFormCompanionSection } from "~shared/types";
import { CurrentCompanion } from "./provider/registrationFormProviderContext";
import { RegistrationDataField } from "./RegistrationDataField";

interface Props {
	stepId: number;
	currentCompanion: CurrentCompanion;
	section: IRegistrationFormChildSection<IRegistrationFormCompanionSection>;
	onClose: () => void;
	currentStepValues: Record<number, any>;
}

export const RegistrationFormCompanionDrawer: FC<Props> = ({
	stepId,
	section,
	currentCompanion,
	onClose,
	currentStepValues,
}) => {
	const { t } = useTranslation();
	const { validationRuleMap, validate, submitCompanion, initialValues } = useRegistrationCompanionForm(
		section,
		currentCompanion,
	);

	return (
		<CrudDrawer
			validate={validate}
			open={!!currentCompanion}
			onSubmit={async (values) => {
				submitCompanion(values, onClose);
				return true;
			}}
			initialValues={initialValues}
			onClose={onClose}
			heading={t("Companion")}
			translations={{ save: currentCompanion.index === -1 ? t("Add companion") : t("Update companion") }}
			body={
				<>
					{section.section.children.map((dataFieldOrBlock) => {
						if (dataFieldOrBlock.entityType === "block") {
							return (
								<RenderView
									key={`${dataFieldOrBlock.entityType}_${dataFieldOrBlock.block.id}`}
									block={dataFieldOrBlock.block}
								/>
							);
						}
						return (
							<RegistrationDataField
								key={`${dataFieldOrBlock.entityType}_${dataFieldOrBlock.dataField.id}`}
								stepId={stepId}
								sectionId={section.section.id}
								dataField={dataFieldOrBlock.dataField}
								validationRules={validationRuleMap[dataFieldOrBlock.dataField.id] ?? []}
								additionalStepValues={currentStepValues}
							/>
						);
					})}
				</>
			}
		/>
	);
};
