import { RuleTypes } from "~shared/types/rules";
import { BaseRule, TranslatedError } from "./baseRule";

export class ReadOnlyRuleImpl extends BaseRule {
	type: RuleTypes = RuleTypes.READ_ONLY;

	isValid(): boolean {
		return true;
	}

	getErrorMessage(): TranslatedError {
		const key = "Field is not updatable";
		const values = {};

		return {
			key,
			values,
		};
	}
}
