import { Button, RenderView, Section, SubmitButton } from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { usePMAnalytics } from "~root/analytics/PMAnalyticsProvider";
import { useRegistrationShop } from "~root/registration/shop/RegistrationShopProvider";
import { IRegistrationFormStep, IRegistrationFormStepChild } from "~shared/types";
import { useRegistrationForm } from "./provider/registrationFormProviderContext";
import { createInitialValues, getSectionsFromStep, toDataFieldChildrenFromSection } from "./registrationFormUtils";
import { RegistrationSection } from "./RegistrationSection";

const StyledSection = styled(Section)`
	padding: ${({ theme }) => theme.spacing(4)};
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0 0 ${({ theme }) => theme.spacing(4)} 0;
	gap: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
	step: IRegistrationFormStep;
}

const getButtonSectionColor = (children: IRegistrationFormStepChild[]) => {
	const lastSection = children.length > 0 ? children[children.length - 1] : null;

	if (lastSection?.entityType === "section") {
		return lastSection.section.backgroundColor;
	}
};

export const RegistrationStep: FC<Props> = ({ step }) => {
	const { registrationId = "", stepId = "" } = useParams<{ stepId: string; registrationId: string }>();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const enabledShop = useRegistrationShop();
	const { trackNextStep, trackPreviousStep, trackRegistrationAborted, trackSummaryStarted, trackShopStarted } =
		usePMAnalytics();
	const {
		filledSteps,
		registrationForm: { guest },
		validateStep,
		previousStep,
		submitStep,
	} = useRegistrationForm();

	const onPreviousStepClick = useCallback(() => {
		const previousStepId = previousStep(step.id);

		if (previousStepId === null) {
			trackRegistrationAborted(registrationId, step.id);
			navigate("../landing-page");
		} else {
			trackPreviousStep(registrationId, step.id, Number(previousStepId));
			navigate(`../${previousStepId}`);
		}
	}, [navigate, previousStep, registrationId, step.id, trackPreviousStep, trackRegistrationAborted]);

	const initialValues = useMemo(() => {
		return (
			filledSteps[step.id] ??
			createInitialValues(
				getSectionsFromStep(step)
					.filter((section) => section.section.type === "default")
					.flatMap(toDataFieldChildrenFromSection)
					.map((d) => d.dataField),
				guest?.dataFieldValues,
			)
		);
	}, [filledSteps, guest, step]);

	const onSubmit = useCallback(
		async (values: Record<string, any>) => {
			const nextStepId = await submitStep(step.id, values);
			if (!nextStepId) {
				if (enabledShop?.showShop) {
					trackShopStarted(registrationId, Number(stepId));
					navigate("../shop");
				} else {
					trackSummaryStarted(registrationId, Number(stepId));
					navigate("../summary");
				}
				return;
			}
			trackNextStep(registrationId, Number(stepId), nextStepId);
			return navigate(`../${nextStepId}`);
		},
		[
			enabledShop?.showShop,
			navigate,
			registrationId,
			step.id,
			stepId,
			submitStep,
			trackNextStep,
			trackShopStarted,
			trackSummaryStarted,
		],
	);

	const validate = useCallback(
		(values: Record<string, any>) => {
			return validateStep(step, values);
		},
		[step, validateStep],
	);

	const buttonSectionColor = useMemo(() => getButtonSectionColor(step.children) || "content", [step.children]);

	return (
		<Formik key={step.id} initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
			<Form>
				{step.children.map((sectionOrBlock) => {
					if (sectionOrBlock.entityType === "block") {
						return (
							<RenderView
								key={`${sectionOrBlock.entityType}_${sectionOrBlock.block.id}`}
								block={sectionOrBlock.block}
							/>
						);
					}
					return (
						<RegistrationSection
							key={`${sectionOrBlock.entityType}_${sectionOrBlock.section.id}`}
							sectionOrBlock={sectionOrBlock}
							validationRules={sectionOrBlock.section.validationRules}
							stepId={step.id}
						/>
					);
				})}
				<StyledSection color={buttonSectionColor}>
					<ButtonWrapper>
						<Button type="button" onClick={onPreviousStepClick}>
							{t("Back")}
						</Button>
						<SubmitButton color="primary">{t("Next")}</SubmitButton>
					</ButtonWrapper>
				</StyledSection>
			</Form>
		</Formik>
	);
};
