import { ActionTypes } from "~shared/types/actions";

export type ActionValue = number | number[];
export abstract class BaseAction<TValue> {
	abstract type: ActionTypes;
	public value: TValue;

	constructor(value: TValue) {
		this.value = value;
	}
}

export abstract class AutomationAction<TValue = ActionValue> extends BaseAction<TValue> {
	abstract applyForAutomation(input: number[]): number[];
}
export abstract class ConditionAction<TValue = ActionValue> extends BaseAction<TValue> {
	abstract getValue(): number | string;
}
