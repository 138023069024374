import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { usePMAnalytics } from "~root/analytics/PMAnalyticsProvider";
import { FirstStepRedirect } from "./FirstStepRedirect";
import { useIsAvailableStep } from "./provider/registrationFormProviderContext";
import { RegistrationStep } from "./RegistrationStep";

const Wrapper = styled.div`
	display: grid;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(5)};
`;

export const RegistrationStepView: FC = () => {
	const { registrationId = "", stepId = "" } = useParams<{ stepId: string; registrationId: string }>();
	const stepStatus = useIsAvailableStep(+stepId);
	const { trackRegistrationStarted } = usePMAnalytics();

	useEffect(() => {
		trackRegistrationStarted(registrationId, Number(stepId));
	}, [registrationId, stepId, trackRegistrationStarted]);

	if (!stepStatus || !stepStatus.isAvailable) {
		return <FirstStepRedirect />;
	}

	return (
		<Wrapper>
			<RegistrationStep step={stepStatus.step} />
		</Wrapper>
	);
};
