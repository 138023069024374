import { DataField } from "./datafield";
import { Group } from "./group";
import { IRegistrationFormSection } from "./section";
import { IRegistrationFormStep } from "./step";
import { Tag } from "./tag";

export enum ActionTypes {
	SHOW_DATA_FIELD = "showDataField",
	SHOW_SECTION = "showSection",
	SHOW_STEP = "showStep",
	ATTACH_GROUP = "attachGroup",
	ATTACH_TAG = "attachTag",
	OVERWRITE_GROUPS = "overwriteGroups",
	OVERWRITE_TAGS = "overwriteTags",
	SEND_EMAIL = "sendEmail",
}

interface BaseAction {
	id: number;
}
export interface SHOW_DATA_FIELD_Action extends BaseAction {
	type: ActionTypes.SHOW_DATA_FIELD;
	value: {
		dataField: DataField;
		section: IRegistrationFormSection;
	};
}
export interface SHOW_SECTION_Action extends BaseAction {
	type: ActionTypes.SHOW_SECTION;
	value: IRegistrationFormSection;
}
export interface SHOW_STEP_Action extends BaseAction {
	type: ActionTypes.SHOW_STEP;
	value: IRegistrationFormStep;
}
export interface ATTACH_GROUP_Action extends BaseAction {
	type: ActionTypes.ATTACH_GROUP;
	value: Group;
}
export interface ATTACH_TAG_Action extends BaseAction {
	type: ActionTypes.ATTACH_TAG;
	value: Tag;
}
export interface OVERWRITE_GROUPS_Action extends BaseAction {
	type: ActionTypes.OVERWRITE_GROUPS;
	value: Group[];
}
export interface OVERWRITE_TAGS_Action extends BaseAction {
	type: ActionTypes.OVERWRITE_TAGS;
	value: Tag[];
}
export interface SEND_MAIL_Action extends BaseAction {
	type: ActionTypes.SEND_EMAIL;
	value: { id: number; name: string };
}

export type Action =
	| SHOW_DATA_FIELD_Action
	| SHOW_SECTION_Action
	| SHOW_STEP_Action
	| ATTACH_GROUP_Action
	| ATTACH_TAG_Action
	| OVERWRITE_GROUPS_Action
	| OVERWRITE_TAGS_Action
	| SEND_MAIL_Action;
