import { DataFieldType } from "~shared/types";
import { RuleTypes } from "~shared/types/rules";
import { BaseRule, TranslatedError } from "./baseRule";

export class IsRequiredRuleImpl extends BaseRule {
	type: RuleTypes = RuleTypes.IS_REQUIRED;

	isValid(): boolean {
		switch (this.dataField.type) {
			case DataFieldType.CHECKBOX_GROUP:
			case DataFieldType.MULTI_SELECT_FIELD: {
				const value = this.value as number[];

				return value instanceof Array && !!value.length;
			}
			case DataFieldType.URL:
			case DataFieldType.EMAIL:
			case DataFieldType.LONG_TEXT:
			case DataFieldType.TEXT:
			case DataFieldType.CHECKBOX:
			case DataFieldType.SELECT_FIELD:
			case DataFieldType.DATE:
			case DataFieldType.TIME:
			case DataFieldType.DATETIME:
			case DataFieldType.FILE:
			case DataFieldType.PHONE:
			case DataFieldType.CRYPT:
			case DataFieldType.RADIO:
				return !!this.value;
			case DataFieldType.NUMBER:
				return typeof this.value === "number";
		}
		return false;
	}

	getErrorMessage(): TranslatedError {
		const key = "Field is required";
		const values = {};

		return {
			key,
			values,
		};
	}
}
