import { DataFieldType } from "~shared/types";
import { RuleTypes } from "~shared/types/rules";
import { BaseRule, TranslatedError } from "./baseRule";

export class MustNotContainRuleImpl extends BaseRule {
	type: RuleTypes = RuleTypes.MUST_NOT_CONTAIN;

	isValid(): boolean {
		switch (this.dataField.type) {
			case DataFieldType.CHECKBOX_GROUP:
			case DataFieldType.MULTI_SELECT_FIELD: {
				const ruleValue = this.ruleValue as number[];
				const value = (this.value as number[]) ?? [];

				return !ruleValue.some((x) => value.includes(x));
			}
			case DataFieldType.URL:
			case DataFieldType.EMAIL:
			case DataFieldType.LONG_TEXT:
			case DataFieldType.TEXT:
			case DataFieldType.CRYPT:
			case DataFieldType.PHONE:
				return !(this.value ? (this.value as string).toLowerCase() : "").includes(
					(this.ruleValue as string).toLowerCase(),
				);
			case DataFieldType.CHECKBOX:
			case DataFieldType.SELECT_FIELD:
				return this.ruleValue !== this.value;
			case DataFieldType.RADIO:
				return this.ruleValue !== parseInt(this.value as string);
		}
		return false;
	}

	getErrorMessage(): TranslatedError {
		const key = "Field {{name}} must not contain {{value}}";
		const values = { name: this.dataField.label, value: "" };

		switch (this.dataField.type) {
			case DataFieldType.RADIO:
				values.value = this.dataField.options.find((x) => x.id === this.ruleValue)?.value ?? "";
				break;
			case DataFieldType.SELECT_FIELD:
				values.value = this.dataField.options.find((x) => x.id === this.ruleValue)?.value ?? "";
				break;
			case DataFieldType.CHECKBOX_GROUP:
			case DataFieldType.MULTI_SELECT_FIELD: {
				const value = this.ruleValue as number[];
				values.value = this.dataField.options
					.filter((x) => value.includes(x.id))
					.map((x) => x.value)
					.join(", ");
				break;
			}
			case DataFieldType.CHECKBOX:
				values.value = this.ruleValue ? "true" : "false";
				break;
			default:
				values.value = this.ruleValue as string;
		}

		return {
			key,
			values,
		};
	}
}
