import { Id } from "@dgs/core";
import { DataField } from "~shared/types";
import { RuleTypes } from "~shared/types/rules";

export type DataFieldValue = string | number | number[] | boolean | Date | null | undefined | string[];
export type TranslatedError = { key: string; values: Record<string, any> };
export type TranslatedErrorProps = { locale: Locale };
export abstract class BaseRule {
	abstract type: RuleTypes;
	public dataField: DataField;
	public sectionId: Id;
	public value: DataFieldValue;
	public ruleValue: DataFieldValue;
	public isNullValid: boolean;

	constructor(
		dataField: DataField,
		sectionId: Id,
		ruleValue: DataFieldValue,
		value: DataFieldValue,
		isNullValid: boolean,
	) {
		this.dataField = dataField;
		this.sectionId = sectionId;
		this.ruleValue = ruleValue;
		this.value = value;
		this.isNullValid = isNullValid;
	}

	abstract isValid(): boolean;
	abstract getErrorMessage(options: TranslatedErrorProps): TranslatedError;
}
