import { Button, Loading, Section, SubmitButton, useDynamicConfig } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { usePMAnalytics } from "~root/analytics/PMAnalyticsProvider";
import { registrationFormService } from "~shared/api/registrationForms";
import { ConsentFormFields } from "../consents/ConsentFormFields";
import { DataFieldLabelRenderer } from "../DataFieldLabelRenderer";
import { DataFieldValueRenderer } from "../DataFieldValueRenderer";
import { ConsentValues, useRegistrationForm } from "../provider/registrationFormProviderContext";
import {
	getSectionsFromStep,
	toConsentErrors,
	toConsentValues,
	toDataFieldChildrenFromSection,
} from "../registrationFormUtils";
import { RenderRegistrationBlock } from "../RenderRegistrationBlock";
import { RegistrationShopPaymentProviders } from "../shop/RegistrationShopPaymentProviders";
import { isShopCorrectlyConfigured, useRegistrationShop } from "../shop/RegistrationShopProvider";
import { RegistrationShopSummary } from "../shop/RegistrationShopSummary";
import { RegistrationCompanionSummary } from "./RegistrationCompanionSummary";

const StyledSection = styled(Section)`
	display: grid;
	gap: ${({ theme }) => theme.spacing(4)};
	padding: ${({ theme }) => theme.spacing(4)};
`;

const SummaryRow = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(0)};
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0 0 ${({ theme }) => theme.spacing(4)} 0;
	gap: ${({ theme }) => theme.spacing(4)};
`;

export const RegistrationSummaryPage = () => {
	const { trackSummaryAborted, trackRegistrationCompleted, trackSummaryAbortedWithShop } = usePMAnalytics();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [finished, setFinished] = useState(false);
	const {
		dataFieldSettings: { emailId },
	} = useDynamicConfig();
	const {
		hiddenSteps,
		getHiddenDataFields,
		getHiddenSections,
		filledSteps,
		previousStep,
		submitRegistration,
		registrationForm,
		companions,
	} = useRegistrationForm();
	const emailDomain = useMemo(() => {
		if (emailId) {
			const values = Object.values(filledSteps).find((x) => x[emailId]);
			if (values) {
				const [, domain] = values[emailId].split("@");
				return `@${domain}`;
			}
		}

		return "";
	}, [filledSteps, emailId]);
	const { data: consents = [], isLoading } = useQuery({
		queryKey: [...registrationFormService.keys.consentsForSummaryPage(registrationForm.id), emailDomain],
		queryFn: () =>
			registrationFormService.consentsForSummaryPage(registrationForm.id, emailDomain).then((x) => x.data.data),
	});
	const hiddenSections = getHiddenSections(filledSteps);
	const hiddenDataFields = getHiddenDataFields(filledSteps);
	const enabledShop = useRegistrationShop();

	const onSubmit = useCallback(
		async (values: ConsentValues) => {
			if (enabledShop) {
				const { chosenShopItems, chosenPaymentProvider } = enabledShop;
				const chosenShopItemIds = Object.keys(chosenShopItems);

				const success = await submitRegistration({
					summaryPageConsents: values,
					paymentProviderId: chosenPaymentProvider?.id ?? null,
					shopItems:
						chosenShopItemIds.length > 0
							? chosenShopItemIds.map((id) => ({ shopItemId: id, quantity: chosenShopItems[id] }))
							: null,
				});

				if (success) {
					trackRegistrationCompleted(`${registrationForm.id}`);
				}
			} else {
				const success = await submitRegistration({
					summaryPageConsents: values,
					paymentProviderId: null,
					shopItems: null,
				});

				if (success) {
					trackRegistrationCompleted(`${registrationForm.id}`);
				}
			}
			setFinished(true);
			trackRegistrationCompleted(`${registrationForm.id}`);
		},

		[enabledShop, registrationForm.id, submitRegistration, trackRegistrationCompleted],
	);

	const handleBack = useCallback(() => {
		if (enabledShop?.showShop) {
			trackSummaryAbortedWithShop(`${registrationForm.id}`);
			navigate("../shop");
		} else {
			const previousStepId = previousStep();
			if (previousStepId !== null) {
				trackSummaryAborted(`${registrationForm.id}`, previousStepId);
				navigate(`../${previousStepId}`);
			}
		}
	}, [
		enabledShop?.showShop,
		navigate,
		previousStep,
		registrationForm.id,
		trackSummaryAborted,
		trackSummaryAbortedWithShop,
	]);

	if (!Object.keys(filledSteps).length) {
		return <Navigate to=".." />;
	}

	if (isLoading)
		return (
			<StyledSection color="content">
				<Loading />
			</StyledSection>
		);

	return (
		<>
			{registrationForm.summaryBlockId && <RenderRegistrationBlock blockId={registrationForm.summaryBlockId} />}
			<StyledSection color="content">
				{registrationForm.steps
					.filter((x) => !hiddenSteps.includes(x.id))
					.map((step) => {
						const filledStep = filledSteps[step.id] ?? {};
						const validSections = getSectionsFromStep(step).filter((x) => !hiddenSections.includes(x.section.id));

						return (
							<Fragment key={step.id}>
								{validSections.map((section) => {
									const dataFields = toDataFieldChildrenFromSection(section)
										.filter((x) => !hiddenDataFields.includes(`${section.section.id}-${x.dataField.id}`))
										.map((x) => x.dataField);

									if (section.section.type === "companion") {
										return (
											companions.length > 0 && (
												<RegistrationCompanionSummary key={section.section.id} dataFields={dataFields} />
											)
										);
									}

									return (
										<Fragment key={section.section.id}>
											{dataFields.map((dataField) => (
												<SummaryRow key={dataField.id}>
													<DataFieldLabelRenderer>{dataField.label}:</DataFieldLabelRenderer>
													<DataFieldValueRenderer dataField={dataField} value={filledStep[dataField.id]} />
												</SummaryRow>
											))}
										</Fragment>
									);
								})}
							</Fragment>
						);
					})}
			</StyledSection>
			{isShopCorrectlyConfigured(enabledShop) && enabledShop.showShop && (
				<StyledSection color="content">
					<RegistrationShopSummary {...enabledShop} />
				</StyledSection>
			)}
			<StyledSection color="content">
				{isShopCorrectlyConfigured(enabledShop) && <RegistrationShopPaymentProviders {...enabledShop} />}
				<Formik
					initialValues={toConsentValues(consents)}
					onSubmit={onSubmit}
					validate={(values: ConsentValues) => toConsentErrors(consents, values, t)}
				>
					<Form>
						{consents.length > 0 && <ConsentFormFields consents={consents} />}
						<ButtonWrapper>
							<Button type="button" onClick={handleBack}>
								{t("Back")}
							</Button>
							<SubmitButton color="primary" disabled={finished}>
								{t("registration.submit")}
							</SubmitButton>
						</ButtonWrapper>
					</Form>
				</Formik>
			</StyledSection>
		</>
	);
};
