import { FooterContact, getFooter, useDynamicConfig } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const TenantFooter = getFooter();

export const Footer: FC = () => {
	const { productData } = useDynamicConfig();
	const { t } = useTranslation();

	const contact: FooterContact | undefined =
		(productData.contactEmail &&
			productData.contactName && {
				label: t("Contact"),
				email: productData.contactEmail,
				name: productData.contactName,
			}) ||
		undefined;

	return <TenantFooter footerLinks={productData.footerLinks} contact={contact} />;
};
