import { useQuery } from "@tanstack/react-query";
import { shopKeys } from "./shopKeys";
import { shopService } from "./shopService";

const shopSettingsFetcher = () => shopService.settings().then((r) => r.data.data);
export const useShopSettingsQuery = () => {
	const { data, isLoading } = useQuery({ queryKey: shopKeys.settings, queryFn: shopSettingsFetcher });

	return {
		data,
		isLoading,
	};
};
