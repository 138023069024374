import { getAppBar, getLocaleSwitch, useDynamicConfig, useLocale } from "@dgs/core";
import React, { FC } from "react";
import { config } from "~root/config";

const AppBar = getAppBar();
const LocaleSwitch = getLocaleSwitch();

export const PMApplicationBar: FC = () => {
	const { productData, brand } = useDynamicConfig();
	const { locale, setLocale } = useLocale();
	const logoUrl = config.themeName === "dgs" ? brand?.logoFile.path ?? productData.logo : productData.logo;

	return (
		<AppBar appIdentifier={productData.htmlTitle} logo={{ logoUrl, logoAltText: productData.htmlTitle }}>
			<LocaleSwitch setLocale={setLocale} locale={locale} availableLocales={productData.contentLocales} />
		</AppBar>
	);
};
