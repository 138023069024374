import { IToast, useToasts } from "@dgs/core";
import axios, { AxiosError, AxiosResponse } from "axios";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
const noop = (response: AxiosResponse) => response;

export const PMAxiosErrorHandler: FC = () => {
	const { showToast } = useToasts();
	const { t } = useTranslation();

	useEffect(() => {
		const errorInterceptor = async (error: AxiosError) => {
			const statusCode = error.response?.status || 500;

			const errorMessages = (
				Object.values(error.response ? (error.response.data as any).errors || {} : {})
					.flat()
					.filter((value, index, self) => {
						return self.indexOf(value) === index;
					}) as string[]
			)
				//TODO: PmAxiosErrorHandler und PMAxiosProvider zusammenlegen / entfernen falls möglich
				.map((v) => t(v as any)) as string[];

			const toast: IToast = {
				type: "error",
				title: t("Error"),
				body: [t("An error has occurred")],
			};

			if (statusCode !== 404) {
				if (errorMessages.length > 0) {
					showToast({ ...toast, body: errorMessages });
				} else {
					showToast(toast);
				}
			}

			return Promise.resolve(error);
		};

		const interceptor = axios.interceptors.response.use(noop, errorInterceptor);

		return () => axios.interceptors.response.eject(interceptor);
	}, [showToast, t]);

	return null;
};
