import { useAnalytics, useDynamicConfig } from "@dgs/core";
import React, { FC, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AllAnalyticsEvents } from "~root/analytics/analyticsEvents";

type EventTypes = "page.ready" | "form.open" | "form.sent" | "newsletter.optout";

declare global {
	interface Window {
		ste_statistic: {
			push(event: Record<string, unknown>): void;
		};
	}
}

window.ste_statistic = window.ste_statistic || [];

export const AdobeAnalytics: FC = () => {
	const { productData } = useDynamicConfig();
	const { listen } = useAnalytics();

	const trackEvent = useCallback((event: EventTypes, data: Record<string, any>) => {
		window.ste_statistic.push({
			action: event,
			data: {
				...data,
			},
		});
	}, []);

	useEffect(() => {
		const unsubscribe = listen((event: AllAnalyticsEvents) => {
			switch (event.type) {
				case "PAGE_VIEWED":
					trackEvent("page.ready", {
						page: {
							country: "WW",
							language: productData.language,
							template: "DGS",
							title: event.payload.pageUrl,
						},
					});
					break;
				case "REGISTRATION_PROCESS_STARTED":
					trackEvent("form.open", {
						form: {
							type: "eventregistration",
							recipient: "general",
						},
					});
					break;
				case "REGISTRATION_PROCESS_COMPLETED":
					trackEvent("form.sent", {
						form: {
							type: "eventregistration",
							recipient: "general",
						},
					});
					break;
				case "REGISTRATION_CANCELED":
				case "REGISTRATION_DECLINED":
				case "REGISTRATION_REPLACEMENT":
					trackEvent("newsletter.optout", {
						newsletter: {
							recipient: productData.htmlTitle,
						},
					});
					break;
			}
		});

		return () => {
			unsubscribe();
		};
	}, [listen, trackEvent, productData.language, productData.htmlTitle]);

	if (productData.adobeAnalyticsThirdPartyScripts === null) {
		return null;
	}

	return (
		<Helmet>
			{productData.adobeAnalyticsThirdPartyScripts.map((scriptProps) => (
				<script key={scriptProps.src} {...scriptProps} />
			))}
		</Helmet>
	);
};
