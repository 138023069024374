import { H5, RadioField, RadioFieldGroup, SlateViewer } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IConsent } from "~shared/types";
import { ConsentWrapper, Error } from "./CheckboxConsent";

interface Props {
	consent: IConsent;
}

const Actions = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
`;

export const RadioConsent: FC<Props> = ({ consent }) => {
	const { t } = useTranslation();
	const fieldName = consent.id.toString();
	const [, meta] = useField(fieldName);

	return (
		<ConsentWrapper>
			{consent.headline && <H5>{consent.headline}</H5>}
			<SlateViewer content={consent.consent} />
			<Actions>
				<RadioFieldGroup name={consent.id.toString()}>
					<RadioField value={"true"} label={t("Agree")} />
					<RadioField value={"false"} label={t("Disagree")} />
				</RadioFieldGroup>
			</Actions>
			{meta.touched && meta.error && <Error id={`${fieldName}_error`}>{meta.error}</Error>}
		</ConsentWrapper>
	);
};
