import { CheckboxField, H5, SlateViewer } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import styled from "styled-components";
import { IConsent } from "~shared/types";

interface Props {
	consent: IConsent;
}

export const ConsentWrapper = styled.div`
	display: grid;
	grid-template-rows: max-content 1fr;
	gap: ${({ theme }) => theme.spacing(1)};
`;

const StyledCheckbox = styled(CheckboxField)`
	align-items: start;

	label {
		color: ${({ theme }) => theme.colors.forms.label};
		font-size: 1rem;
	}
`;

export const Error = styled.div`
	${({ theme }) => theme.forms.error};
	position: relative;
	padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const CheckboxConsent: FC<Props> = ({ consent }) => {
	const fieldName = consent.id.toString();
	const [, meta] = useField(fieldName);

	return (
		<ConsentWrapper>
			{consent.headline && <H5>{consent.headline}</H5>}
			<StyledCheckbox label={<SlateViewer content={consent.consent} />} name={consent.id.toString()} />
			{meta.touched && meta.error && <Error id={`${fieldName}_error`}>{meta.error}</Error>}
		</ConsentWrapper>
	);
};
