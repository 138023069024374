import { FCC, LocaleProvider, useDynamicConfig } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "~root/i18n";
import { storage } from "~shared/storage";

interface Props {
	timezone?: string;
	initialDateFnsLocale?: Locale;
	initialLocale?: string;
}

export const PMLocaleProvider: FCC<Props> = ({
	children,
	initialLocale = Intl.DateTimeFormat().resolvedOptions().locale,
	timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
	const { productData } = useDynamicConfig();
	const queryClient = useQueryClient();
	const [initialized, setInitialized] = useState(false);
	const calculatedInitialLocale = useMemo(() => {
		const initialCode = initialLocale.split("-")[0];
		const codeFromStorage = storage.getItem("locale");
		const activeLocale = codeFromStorage || initialCode;
		if (!productData.contentLocales.includes(activeLocale)) {
			return productData.contentLocales[0];
		} else {
			return activeLocale;
		}
	}, [initialLocale, productData.contentLocales]);

	const changeLanguage = useCallback(
		(language: string) => {
			storage.setItem("locale", language);
			if (i18n.language !== language) {
				void i18n.changeLanguage(language);
			}
			document.documentElement.lang = language;
			axios.defaults.headers["Content-Language"] = language;
			void queryClient.invalidateQueries();
		},
		[queryClient],
	);

	useEffect(() => {
		axios.defaults.headers["Content-Language"] = calculatedInitialLocale;
		void queryClient.invalidateQueries();
		setInitialized(true);
	}, [calculatedInitialLocale, queryClient]);

	return (
		<I18nextProvider i18n={i18n}>
			<LocaleProvider initialLocale={calculatedInitialLocale} initialTimeZone={timezone} onChange={changeLanguage}>
				{initialized && children}
			</LocaleProvider>
		</I18nextProvider>
	);
};
