import { Card, CardBody, IconButton, useLocale } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IShopItem, IShopSettings } from "~shared/types";

interface Props {
	shopItem: IShopItem;
	shopSettings: IShopSettings;
}

const StyledCardBody = styled(CardBody)`
	display: grid;
	grid-template-columns: 1fr max-content max-content max-content;
	gap: ${({ theme }) => theme.spacing(10)};
	align-items: center;
`;

export const ValueWrapper = styled.div`
	display: grid;
	grid-auto-rows: 1fr;
`;

const Actions = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing(3)};
	justify-content: center;
	align-items: center;
`;

export const Text = styled.span`
	font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const LightText = styled.span`
	font-size: ${({ theme }) => theme.fonts.sizes.small};
	color: ${({ theme }) => theme.colors.palette.grey[70]};
`;

const Amount = styled.span`
	font-weight: ${({ theme }) => theme.fonts.weights.bold}
	font-size: ${({ theme }) => theme.fonts.sizes.large};
	color: ${({ theme }) => theme.colors.palette.grey[80]};
`;

export const ShopItemName: FC<{ shopItem: IShopItem }> = ({ shopItem }) => (
	<ValueWrapper>
		<Text>{shopItem.name}</Text>
		<LightText>{shopItem.description}</LightText>
	</ValueWrapper>
);

export const ShopItemGross: FC<Props> = ({ shopItem, shopSettings }) => {
	const { t } = useTranslation();
	const { locale } = useLocale();

	return (
		<ValueWrapper>
			<Text>
				{(shopItem.prices.gross / 100).toLocaleString(locale, {
					style: "currency",
					currency: shopSettings.currency.isoCode,
				})}
			</Text>
			<LightText>{t("Including {{percent}}% VAT", { percent: shopItem.prices.taxInPercent })}</LightText>
		</ValueWrapper>
	);
};

export const RegistrationShopItem: FC<Props> = ({ shopItem, shopSettings }) => {
	const { t } = useTranslation();
	const { locale } = useLocale();
	const [field, , { setValue }] = useField<number>(shopItem.id.toString());

	const onDecrease = useCallback(() => setValue(field.value - 1), [field.value, setValue]);
	const onIncrease = useCallback(() => setValue(field.value + 1), [field.value, setValue]);

	return (
		<Card>
			<StyledCardBody>
				<ShopItemName shopItem={shopItem} />
				<Text>
					{(shopItem.prices.net / 100).toLocaleString(locale, {
						style: "currency",
						currency: shopSettings.currency.isoCode,
					})}
				</Text>
				<ShopItemGross shopItem={shopItem} shopSettings={shopSettings} />
				<Actions>
					<IconButton
						disabled={(!!shopItem.minPerGuest && field.value <= shopItem.minPerGuest) || field.value === 0}
						size="small"
						onClick={onDecrease}
						title={t("Decrease amount of {{item}}", { item: shopItem.name })}
						type="button"
						icon="minus"
					/>
					<Amount>{field.value}</Amount>
					<IconButton
						disabled={!!shopItem.maxPerGuest && field.value >= shopItem.maxPerGuest}
						size="small"
						color={!!shopItem.maxPerGuest && field.value >= shopItem.maxPerGuest ? "default" : "primary"}
						onClick={onIncrease}
						title={t("Increase amount of {{item}}", { item: shopItem.name })}
						type="button"
						icon="plus"
					/>
				</Actions>
			</StyledCardBody>
		</Card>
	);
};
