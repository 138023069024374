import React from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationForm } from "../provider/registrationFormProviderContext";
import { RegistrationDefaultEndPage } from "./RegistrationDefaultEndPage";

export const RegistrationCancelledPage = () => {
	const { t } = useTranslation();
	const { registrationForm } = useRegistrationForm();

	return (
		<RegistrationDefaultEndPage
			headline={t("Cancellation confirmed")}
			text={t("Thank you for letting us know that unfortunately you will not be able to attend after all.")}
			blockId={registrationForm.cancelledBlockId}
		/>
	);
};
