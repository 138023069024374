import { ActionTypes } from "~shared/types/actions";
import { BaseAction } from "./baseAction";

export class OverwriteGroupsAction extends BaseAction<number[]> {
	type = ActionTypes.OVERWRITE_GROUPS;

	apply(): number[] {
		return this.value;
	}
}
