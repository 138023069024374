import { FCC, Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { registrationFormService } from "~shared/api/registrationForms";
import { useRegistrationFormPaymentProvidersQuery } from "~shared/api/registrationForms/registrationFormQueries";
import { useShopSettingsQuery } from "~shared/api/shop";
import { IShopItem } from "~shared/types";
import { IPaymentProvider } from "~shared/types/paymentProvider";
import { IShopSettings } from "~shared/types/shopSettings";
import { useSessionState } from "~shared/utils/useSessionState";
import { useRegistrationForm } from "../provider/registrationFormProviderContext";
import { flattenStepDataFields } from "../registrationFormUtils";

export type ChosenShopItems = Record<Id, number>;

export interface RegistrationShopContextState {
	isLoading: boolean;
	countryId: Id | null;
	isBusiness: boolean | null;
	shopSettings?: IShopSettings;
	shopItems: IShopItem[];
	chosenShopItems: ChosenShopItems;
	paymentProviders: IPaymentProvider[];
	setChosenShopItems: (chosenShopItems: ChosenShopItems) => void;
	chosenPaymentProvider: IPaymentProvider | null;
	onChosePaymentProvider: (provider: IPaymentProvider) => void;
	showShop: boolean;
}
export interface CorrectConfiguredShopContextState {
	isLoading: boolean;
	countryId: Id;
	isBusiness: boolean;
	shopItems: IShopItem[];
	shopSettings: IShopSettings;
	chosenShopItems: ChosenShopItems;
	paymentProviders: IPaymentProvider[];
	setChosenShopItems: (chosenShopItems: ChosenShopItems) => void;
	chosenPaymentProvider: IPaymentProvider | null;
	onChosePaymentProvider: (provider: IPaymentProvider) => void;
	showShop: boolean;
}

export const RegistrationShopContext = React.createContext<RegistrationShopContextState | undefined>(undefined);

export const RegistrationShopProvider: FCC = ({ children }) => {
	const { registrationForm, filledSteps } = useRegistrationForm();
	const [chosenShopItems, _setChosenShopItems] = useSessionState<ChosenShopItems>("chosenShopItems", {});
	const [chosenPaymentProvider, onChosePaymentProvider] = useState<IPaymentProvider | null>(null);
	const navigate = useNavigate();
	const { data: shopSettings, isLoading } = useShopSettingsQuery();
	const { data: paymentProviders, isLoading: isPaymentProviderLoading } = useRegistrationFormPaymentProvidersQuery(
		registrationForm.id,
	);

	const showShop = registrationForm.isShopEnabled && !registrationForm.guest?.isRegistered;

	const { countryId, isBusiness } = useMemo(() => {
		const datafieldValues = flattenStepDataFields(filledSteps);

		if (!shopSettings) {
			return { countryId: null, isBusiness: null };
		}

		return {
			countryId: shopSettings.countryDataField?.id ? (datafieldValues[shopSettings.countryDataField.id] as Id) : null,
			isBusiness: shopSettings.isBusinessDataField?.id ? !!datafieldValues[shopSettings.isBusinessDataField.id] : null,
		};
	}, [filledSteps, shopSettings]);

	const { data: shopItems = [], isLoading: isShopItemsLoading } = useQuery({
		queryKey: [...registrationFormService.keys.shopItems(registrationForm.id), countryId, isBusiness],
		queryFn: () => {
			if (countryId && isBusiness !== null)
				return registrationFormService
					.getShopItems(registrationForm.id, countryId, isBusiness)
					.then((r) => r.data.data);
		},
		enabled: !!countryId && isBusiness !== null,
	});

	const setChosenShopItems = useCallback(
		(values: ChosenShopItems) => {
			const actualChosenShopItems = Object.keys(values).reduce(
				(current, next) => (values[next] > 0 ? { ...current, [next]: values[next] } : current),
				{},
			);

			_setChosenShopItems(actualChosenShopItems);
			navigate("summary");
		},
		[_setChosenShopItems, navigate],
	);

	useEffect(() => {
		if (paymentProviders.length === 1) {
			onChosePaymentProvider(paymentProviders[0]);
		}
	}, [paymentProviders, onChosePaymentProvider]);

	return (
		<RegistrationShopContext.Provider
			value={{
				isLoading: isLoading || isPaymentProviderLoading || isShopItemsLoading,
				countryId,
				isBusiness,
				shopSettings,
				chosenShopItems,
				shopItems,
				setChosenShopItems,
				chosenPaymentProvider,
				onChosePaymentProvider,
				paymentProviders,
				showShop,
			}}
		>
			{children}
		</RegistrationShopContext.Provider>
	);
};

export const useRegistrationShop = () => {
	const { registrationForm } = useRegistrationForm();
	const context = useContext(RegistrationShopContext);

	if (registrationForm.isShopEnabled && !registrationForm.guest?.isRegistered) {
		if (context === undefined) {
			throw new Error(`Context undefined. Are you missing the RegistrationShopProvider?`);
		}

		return context;
	}
	return null;
};

export const isShopCorrectlyConfigured = (
	enabledShop: RegistrationShopContextState | null,
): enabledShop is CorrectConfiguredShopContextState => {
	return Boolean(enabledShop && enabledShop.shopSettings && enabledShop.countryId && enabledShop.isBusiness !== null);
};
