export enum DataFieldType {
	TEXT = "text",
	LONG_TEXT = "longText",
	EMAIL = "email",
	NUMBER = "number",
	URL = "url",
	RADIO = "radioField",
	SELECT_FIELD = "selectField",
	MULTI_SELECT_FIELD = "multiSelectField",
	CHECKBOX_GROUP = "checkboxGroup",
	CHECKBOX = "checkbox",
	FILE = "file",
	DATE = "date",
	TIME = "time",
	DATETIME = "dateTime",
	PHONE = "phone",
	CRYPT = "crypt",
}
