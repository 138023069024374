import { useField } from "formik";
import React, { FC, useEffect, useMemo } from "react";
import { DataField, Rule, RuleTypes } from "~shared/types";
import { useIsAvailableDataFields, useRegistrationForm } from "./provider/registrationFormProviderContext";
import { RegistrationFormDataFieldBase } from "./RegistrationFormDataFieldBase";

interface Props {
	stepId: number;
	sectionId: number;
	dataField: DataField;
	validationRules: Rule[];
	additionalStepValues?: Record<number, any>;
}

export const RegistrationDataField: FC<Props> = ({
	dataField,
	sectionId,
	stepId,
	validationRules,
	additionalStepValues,
}) => {
	const [{ value }, , { setValue }] = useField(dataField.id.toString());
	const isAvailable = useIsAvailableDataFields(stepId, sectionId, dataField.id, additionalStepValues);
	const { isPreview } = useRegistrationForm();
	const required = useMemo(
		() => !isPreview && validationRules.some((x) => x.type === RuleTypes.IS_REQUIRED),
		[isPreview, validationRules],
	);
	const { registrationForm } = useRegistrationForm();
	const readOnly = useMemo(() => {
		if (!registrationForm.guest) {
			return false;
		}
		if (validationRules.some((x) => x.type === RuleTypes.READ_ONLY)) {
			return true;
		}
		const rule = validationRules.find((x) => x.type === RuleTypes.NOT_UPDATABLE);
		if (rule) {
			const value = registrationForm.guest?.dataFieldValues.find((value) => value.id === rule.dataField.id)?.value;
			if (Array.isArray(value)) {
				return value.length > 0;
			} else {
				return value !== null && value !== "" && value !== undefined;
			}
		}
		return false;
	}, [validationRules, registrationForm]);

	useEffect(() => {
		if (!isAvailable) {
			setValue(undefined);
		} else if (value === undefined) {
			setValue(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataField.id, isAvailable]);

	if (!isAvailable) {
		return null;
	}

	return (
		<RegistrationFormDataFieldBase
			dataField={dataField}
			required={required}
			readOnly={readOnly}
			validationRules={validationRules}
		/>
	);
};
