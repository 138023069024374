import { FCC, getTheme, useBrandTheme, useDynamicConfig } from "@dgs/core";
import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { config } from "~root/config";

export const PMThemeProvider: FCC = ({ children }) => {
	const { brand } = useDynamicConfig();
	const theme = getTheme(config.mode);
	const brandTheme = useBrandTheme(config.themeName, theme, brand);

	useEffect(() => {
		const faviconElement = document.getElementById("favicon") as HTMLLinkElement;

		if (brand && brand?.faviconFile) {
			faviconElement.href = brand?.faviconFile.path;
		}
	}, [brand]);

	return <ThemeProvider theme={brandTheme}>{children}</ThemeProvider>;
};
