import { Loading, useDynamicConfig } from "@dgs/core";
import React, { FC, useEffect, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RegistrationPreviewPage } from "~root/registration/RegistrationPreviewPage";
import { RegistrationClosedPage } from "~root/registration/staticPages";
import { useRegistrationFormDetailsQuery } from "~shared/api/registrationForms";
import { isRegistrationFormClosed, isRegistrationFormPrivate } from "~shared/types/registrationForm";
import { RegistrationFormAvailablePage } from "./RegistrationFormAvailablePage";
import { RegistrationFormCodePage } from "./RegistrationFormCodePage";
import { RegistrationFormPrivatePage } from "./RegistrationFormPrivatePage";

export const RegistrationPage: FC = () => {
	const location = useLocation();

	useEffect(() => {
		document.querySelector("#scroll-container")?.scrollTo({
			top: 0,
		});
	}, [location]);

	return (
		<Routes>
			<Route index element={<RedirectToCachedOrDefaultRegistration />} />
			<Route path="/:registrationId/*" element={<RegistrationContentPage />} />
			<Route path="/preview/:registrationId/*" element={<RegistrationPreviewPage />} />
		</Routes>
	);
};

export const RedirectToCachedOrDefaultRegistration: FC = () => {
	const navigate = useNavigate();
	const registrationFormId = sessionStorage.getItem("registrationFormId");
	const {
		productData: { defaultRegistrationFormId },
	} = useDynamicConfig();

	useEffect(() => {
		if (registrationFormId) {
			navigate(registrationFormId);
		} else {
			defaultRegistrationFormId ? navigate(defaultRegistrationFormId.toString()) : navigate("..");
		}
	}, [defaultRegistrationFormId, navigate, registrationFormId]);

	return null;
};

export const RegistrationContentPage: FC = () => {
	const { registrationId = "" } = useParams<"registrationId">();
	const token = useRegistrationToken();
	const { data: registrationForm, isLoading } = useRegistrationFormDetailsQuery(registrationId, token);

	useEffect(() => {
		if (registrationForm) {
			sessionStorage.setItem("registrationFormId", registrationForm.id.toString());
		}
	}, [registrationForm]);

	if (isLoading || !registrationForm) {
		return <Loading />;
	}

	if (isRegistrationFormPrivate(registrationForm)) {
		if (registrationForm.isCodePageEnabled && !token) {
			return <RegistrationFormCodePage registrationForm={registrationForm} block={registrationForm.codePageBlock} />;
		}
		return <RegistrationFormPrivatePage block={registrationForm.privateBlock} />;
	} else if (isRegistrationFormClosed(registrationForm)) {
		return <RegistrationClosedPage block={registrationForm.closedBlock} />;
	}

	return <RegistrationFormAvailablePage registrationForm={registrationForm} />;
};

const useRegistrationToken = () => {
	const [searchParams] = useSearchParams();
	const sessionToken = sessionStorage.getItem("registrationToken");
	const token = useMemo(() => searchParams.get("token") || sessionToken || null, [searchParams, sessionToken]);

	useEffect(() => {
		token &&
			token !== sessionStorage.getItem("registrationToken") &&
			sessionStorage.setItem("registrationToken", token);
	}, [token]);

	return token;
};
