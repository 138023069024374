import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FilledSteps } from "~root/registration/provider/registrationFormProviderContext";
import { getDataFieldsFromForm } from "~root/registration/registrationFormUtils";
import { RegistrationDeclinePage } from "~root/registration/staticPages/RegistrationDeclinePage";
import { RegistrationPaymentErrorPage } from "~root/registration/staticPages/RegistrationPaymentErrorPage";
import { RegistrationPaymentPendingPage } from "~root/registration/staticPages/RegistrationPaymentPendingPage";
import { RegistrationPaymentSuccessfulPage } from "~root/registration/staticPages/RegistrationPaymentSuccessfulPage";
import { RegistrationScreeningConfirmedPage } from "~root/registration/staticPages/RegistrationScreeningConfirmedPage";
import { useIndexedDb } from "~shared/storage/IndexedDbProvider";
import { DataFieldType } from "~shared/types";
import { IRegistrationFormAvailableShowResource } from "~shared/types/registrationForm";
import { RegistrationFormProvider } from "./provider/RegistrationFormProvider";
import { RegistrationStepView } from "./RegistrationStepView";
import { RegistrationShopPage } from "./shop/RegistrationShopPage";
import { RegistrationShopProvider } from "./shop/RegistrationShopProvider";
import {
	RegistrationCancelPage,
	RegistrationCancelledPage,
	RegistrationDeclinedPage,
	RegistrationEditedPage,
	RegistrationLandingPage,
	RegistrationNominateReplacementPage,
	RegistrationReplacedPage,
	RegistrationSuccessPage,
	RegistrationSummaryPage,
	RegistrationWaitingListPage,
} from "./staticPages";

interface Props {
	registrationForm: IRegistrationFormAvailableShowResource;
}

export const FILLED_STEPS = "filledSteps";

export const RegistrationFormAvailablePage: FC<Props> = ({ registrationForm }) => {
	const [initialFilledSteps, setInitialFilledSteps] = useState<FilledSteps>({});
	const [isInitialized, setIsInitialized] = useState(false);
	const { get, clearObjectStore } = useIndexedDb();

	const hasFileField = useMemo(
		() => getDataFieldsFromForm(registrationForm).some((dataField) => dataField.type === DataFieldType.FILE),
		[registrationForm],
	);

	const initializeRegistration = useCallback(async () => {
		const sessionStoredData = sessionStorage.getItem(FILLED_STEPS);

		if (!sessionStoredData) {
			if (hasFileField) {
				await clearObjectStore("guest_files");
			}
			setIsInitialized(true);
			return;
		}

		const storedFilledSteps = JSON.parse(sessionStoredData) as FilledSteps;
		const dataFields = getDataFieldsFromForm(registrationForm);

		for (const filledStepId of Object.keys(storedFilledSteps)) {
			for (const dataFieldId of Object.keys(storedFilledSteps[+filledStepId])) {
				const dataField = dataFields.find((dataField) => `${dataField.id}` == dataFieldId);

				if (!dataField || dataField.type !== DataFieldType.FILE) {
					continue;
				}

				const valueId = storedFilledSteps[+filledStepId][dataFieldId];

				storedFilledSteps[+filledStepId][dataFieldId] = await get("guest_files", valueId);
			}
		}

		setInitialFilledSteps(storedFilledSteps);
		setIsInitialized(true);
	}, [clearObjectStore, get, hasFileField, registrationForm]);

	useEffect(() => {
		initializeRegistration();
	}, [initializeRegistration]);

	const showShop = registrationForm.isShopEnabled && !registrationForm.guest?.isRegistered;

	const ShopWrapper = showShop ? RegistrationShopProvider : React.Fragment;

	return (
		isInitialized && (
			<RegistrationFormProvider
				initialFilledSteps={initialFilledSteps}
				registrationForm={registrationForm}
				hasFileField={hasFileField}
			>
				<ShopWrapper>
					<Routes>
						<Route index element={<Navigate to="landing-page" />} />
						<Route path="landing-page" element={<RegistrationLandingPage />} />
						<Route path=":stepId" element={<RegistrationStepView />} />
						<Route path="summary" element={<RegistrationSummaryPage />} />
						{showShop && <Route path="shop" element={<RegistrationShopPage />} />}
						<Route path="success" element={<RegistrationSuccessPage />} />
						<Route path="edited" element={<RegistrationEditedPage />} />
						<Route path="decline" element={<RegistrationDeclinePage />} />
						<Route path="nominate-replacement" element={<RegistrationNominateReplacementPage />} />
						<Route path="replaced" element={<RegistrationReplacedPage />} />
						<Route path="declined" element={<RegistrationDeclinedPage />} />
						<Route path="cancel" element={<RegistrationCancelPage />} />
						<Route path="cancelled" element={<RegistrationCancelledPage />} />
						<Route path="payment-callback" element={<RegistrationPaymentPendingPage />} />
						<Route path="payment-error" element={<RegistrationPaymentErrorPage />} />
						<Route path="waiting-list" element={<RegistrationWaitingListPage />} />
						<Route path="confirmed" element={<RegistrationScreeningConfirmedPage />} />
						<Route path="payment-successful" element={<RegistrationPaymentSuccessfulPage />} />
					</Routes>
				</ShopWrapper>
			</RegistrationFormProvider>
		)
	);
};
