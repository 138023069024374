import { ActionTypes } from "~shared/types/actions";
import { BaseAction } from "./baseAction";

export class AttachTagAction extends BaseAction<number> {
	type = ActionTypes.ATTACH_TAG;

	apply(input: number[]) {
		return [...input, this.value];
	}
}
