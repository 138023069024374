import { DataField, IRegistrationFormSection } from "~shared/types";
import { ActionTypes } from "~shared/types/actions";

export type SectionsDataFieldsMap = Record<number, Record<number, boolean>>;
export class ShowDataFieldAction {
	type = ActionTypes.SHOW_DATA_FIELD;
	public value: { dataField: DataField; section: IRegistrationFormSection };

	constructor(value: { dataField: DataField; section: IRegistrationFormSection }) {
		this.value = value;
	}

	getValue() {
		return `${this.value.section.id}-${this.value.dataField.id}`;
	}
}
