import { format, isEqual, parse, parseISO } from "date-fns";
import { DataFieldType } from "~shared/types";
import { RuleTypes } from "~shared/types/rules";
import { BaseRule, TranslatedError, TranslatedErrorProps } from "./baseRule";

export class HasValueRuleImpl extends BaseRule {
	type: RuleTypes = RuleTypes.HAS_VALUE;

	isValid(): boolean {
		if (this.value === undefined || this.value === null) {
			return false;
		}
		switch (this.dataField.type) {
			case DataFieldType.CHECKBOX_GROUP:
			case DataFieldType.MULTI_SELECT_FIELD: {
				const ruleValue = this.ruleValue as number[];
				const value = this.value as number[];

				return ruleValue.length === value.length && ruleValue.every((x) => value.includes(x));
			}
			case DataFieldType.URL:
			case DataFieldType.EMAIL:
			case DataFieldType.LONG_TEXT:
			case DataFieldType.TEXT:
			case DataFieldType.CRYPT:
			case DataFieldType.CHECKBOX:
			case DataFieldType.SELECT_FIELD:
			case DataFieldType.TIME:
			case DataFieldType.PHONE:
				return this.ruleValue === this.value;
			case DataFieldType.NUMBER:
				return +(this.ruleValue as number) === +this.value;
			case DataFieldType.RADIO:
				return this.ruleValue === parseInt(this.value as string);
			case DataFieldType.DATE:
				return isEqual(
					parse(this.ruleValue as string, "yyyy-MM-dd", new Date()),
					parse(this.value as string, "yyyy-MM-dd", new Date()),
				);
			case DataFieldType.DATETIME:
				return isEqual(parseISO(this.ruleValue as string), parseISO(this.value as string));
		}
		return false;
	}

	getErrorMessage({ locale }: TranslatedErrorProps): TranslatedError {
		const key = "Field must have value {{value}}";
		const values = { value: "" };

		switch (this.dataField.type) {
			case DataFieldType.RADIO:
				values.value = this.dataField.options.find((x) => x.id === this.ruleValue)?.value ?? "";
				break;
			case DataFieldType.SELECT_FIELD:
				values.value = this.dataField.options.find((x) => x.id === this.ruleValue)?.value ?? "";
				break;
			case DataFieldType.CHECKBOX_GROUP:
			case DataFieldType.MULTI_SELECT_FIELD: {
				const value = this.ruleValue as number[];
				values.value = this.dataField.options
					.filter((x) => value.includes(x.id))
					.map((x) => x.value)
					.join(", ");
				break;
			}
			case DataFieldType.CHECKBOX:
				values.value = this.ruleValue ? "true" : "false";
				break;
			case DataFieldType.DATETIME:
				values.value = format(parseISO(this.ruleValue as string), "Pp", { locale });
				break;
			case DataFieldType.DATE:
				values.value = format(parse(this.ruleValue as string, "yyyy-MM-dd", new Date()), "P", { locale });
				break;
			default:
				values.value = this.ruleValue as string;
		}

		return {
			key,
			values,
		};
	}
}
