import { AnyMenuItem, getNavBar, useDynamicConfig } from "@dgs/core";
import React, { FC, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMenuQuery } from "~shared/api/menu";

const NavBar = getNavBar();

export const PMNavBar: FC = () => {
	const { productData } = useDynamicConfig();
	const { items, isLoading } = useMenuQuery();
	const location = useLocation();
	const navigate = useNavigate();

	const handleNavigate = useCallback(
		(item: AnyMenuItem) => {
			if (item.type === "PAGE") {
				navigate(`blocks/${item.block.slug}`, { replace: true });
			} else if (item.type === "INTERNAL") {
				navigate(item.url, { replace: true });
			}
		},
		[navigate],
	);

	useEffect(() => {
		if (!isLoading && location.pathname === "/") {
			if (productData.startPageMenuItem) {
				handleNavigate(productData.startPageMenuItem);
			} else if (productData.defaultRegistrationFormId) {
				navigate(`registrations/${productData.defaultRegistrationFormId}`, { replace: true });
			} else if (items.length > 0) {
				handleNavigate(items.filter((i) => i.type === "INTERNAL" || i.type === "PAGE")[0]);
			}
		}
	}, [
		handleNavigate,
		isLoading,
		items,
		location.pathname,
		navigate,
		productData.defaultRegistrationFormId,
		productData.startPageMenuItem,
	]);

	return <NavBar appIdentifier={productData.htmlTitle} items={items} />;
};
