import { Id, useLocale } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "./RegistrationShopItem";
import { CorrectConfiguredShopContextState } from "./RegistrationShopProvider";
import { RegistrationSummaryShopItem } from "./RegistrationSummaryShopItem";

const SummaryGrid = styled.div<{ $hasDeposits: boolean }>`
	display: grid;
	grid-template-columns: 3fr 1fr max-content ${({ $hasDeposits }) => ($hasDeposits ? "max-content" : "")} max-content;
	gap: ${({ theme }) => theme.spacing(5)};
	align-items: center;
`;

const Divider = styled.div<{ $hasDeposits: boolean }>`
	grid-column: span ${({ $hasDeposits }) => ($hasDeposits ? "5" : "4")};
	background-color: ${({ theme }) => theme.colors.palette.grey[80]};
	height: 1px;
`;
const TotalRow = styled(Text)<{ $hasDeposits: boolean }>`
	grid-column: ${({ $hasDeposits }) => ($hasDeposits ? "5" : "4")};
`;

const RegistrationSummaryHeaderRow: FC<{ hasDeposits: boolean }> = ({ hasDeposits = false }) => {
	const { t } = useTranslation();

	return (
		<>
			<Text>{t("Item")}</Text>
			<Text>{t("Net")}</Text>
			<Text>{t("Gross / VAT")}</Text>
			{hasDeposits && <Text>{t("Deposit in percent")}</Text>}
			<Text>{t("Total")}</Text>
		</>
	);
};

export const RegistrationShopSummary: FC<CorrectConfiguredShopContextState> = ({
	shopSettings,
	shopItems,
	chosenShopItems,
}) => {
	const { t } = useTranslation();
	const { locale } = useLocale();

	const total = useMemo(() => {
		return Object.keys(chosenShopItems).reduce(
			(prev, curr) =>
				prev + chosenShopItems[curr] * (shopItems.find((shopItem) => shopItem.id === curr)?.prices.gross || 0),
			0,
		);
	}, [chosenShopItems, shopItems]);

	const hasDeposits = useMemo(
		() =>
			Object.keys(chosenShopItems).some(
				(id) => !!shopItems.find((shopItem) => shopItem.id.toString() === id)?.depositInPercent,
			),
		[chosenShopItems, shopItems],
	);

	return (
		<SummaryGrid $hasDeposits={hasDeposits}>
			<RegistrationSummaryHeaderRow hasDeposits={hasDeposits} />
			{Object.keys(chosenShopItems).map((id: Id) => {
				const currentItem = shopItems.find((item) => item.id === id);

				return (
					currentItem && (
						<React.Fragment key={id}>
							<RegistrationSummaryShopItem
								key={id}
								shopItem={currentItem}
								amount={chosenShopItems[id]}
								shopSettings={shopSettings}
							/>
							<Divider $hasDeposits={hasDeposits} />
						</React.Fragment>
					)
				);
			})}
			<Text>{t("Total")}:</Text>
			<TotalRow $hasDeposits={hasDeposits}>
				{(total / 100).toLocaleString(locale, { style: "currency", currency: shopSettings.currency.isoCode })}
			</TotalRow>
		</SummaryGrid>
	);
};
