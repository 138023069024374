import { LaravelResponse, keyToUrl } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const InitiateRegistrationPage: FC = () => {
	const navigate = useNavigate();
	const { guestId = "" } = useParams<"guestId">();
	const queryKey = ["registration-forms", "for-guest", guestId];
	const { data } = useQuery({
		enabled: !!guestId,
		queryKey,
		queryFn: () =>
			axios.get<LaravelResponse<{ registrationFormId: string }>>(keyToUrl(queryKey)).then((r) => r.data.data),
	});

	useEffect(() => {
		if (data && data.registrationFormId) {
			navigate(`/registrations/${data.registrationFormId}?token=${guestId}`);
		}
	}, [data, guestId, navigate]);

	return null;
};
