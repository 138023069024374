import React, { FC } from "react";
import { IConsent } from "~shared/types";
import { CheckboxConsent } from "./CheckboxConsent";
import { NoticeConsent } from "./NoticeConsent";
import { RadioConsent } from "./RadioConsent";

interface Props {
	consent: IConsent;
}

export const RegistrationConsent: FC<Props> = ({ consent }) => {
	switch (consent.type) {
		case "checkbox":
			return <CheckboxConsent consent={consent} />;
		case "radio":
			return <RadioConsent consent={consent} />;
		case "notice":
		default:
			return <NoticeConsent consent={consent} />;
	}
};
