import { Button, Empty, H2, LinkButton, Loading, Section, SubmitButton } from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { usePMAnalytics } from "~root/analytics/PMAnalyticsProvider";
import { useRegistrationForm } from "../provider/registrationFormProviderContext";
import { RegistrationShopItem } from "./RegistrationShopItem";
import {
	ChosenShopItems,
	CorrectConfiguredShopContextState,
	isShopCorrectlyConfigured,
	useRegistrationShop,
} from "./RegistrationShopProvider";

const ShopItemsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: ${({ theme }) => theme.spacing(4)};
`;

const Headline = styled(H2)`
	padding: ${({ theme }) => theme.spacing(4)} 0;
`;

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: ${({ theme }) => theme.spacing(2)};
	padding: ${({ theme }) => theme.spacing(5)} 0;
`;

export const RegistrationShopPage: FC = () => {
	const enabledShop = useRegistrationShop();
	const { t } = useTranslation();
	const { previousStep } = useRegistrationForm();

	if (enabledShop && !enabledShop.countryId) {
		return (
			<Section>
				<Headline>{t("Shop")}</Headline>
				<Empty>
					{t(
						"In order to retrieve data it is necessary to specify the country of the invoice recipient as well as company information if available.",
					)}
				</Empty>
				<Actions>
					<Button type="button" onClick={() => previousStep()}>
						{t("Back")}
					</Button>
					<LinkButton to="../summary" type="button" color="primary">
						{t("Next")}
					</LinkButton>
				</Actions>
			</Section>
		);
	}

	if (!isShopCorrectlyConfigured(enabledShop)) {
		return (
			<Section>
				<Headline>{t("Shop")}</Headline>
				<Empty>{t("The Shop module is not configured correctly.")}</Empty>
				<Actions>
					<Button type="button" onClick={() => previousStep()}>
						{t("Back")}
					</Button>
					<LinkButton to="../summary" type="button" color="primary">
						{t("Next")}
					</LinkButton>
				</Actions>
			</Section>
		);
	}

	return <ShopItems {...enabledShop} />;
};

const ShopItems: FC<CorrectConfiguredShopContextState> = (props) => {
	const { registrationFormId = "" } = useParams<"registrationFormId">();
	const { t } = useTranslation();
	const { isLoading, shopItems, chosenShopItems, setChosenShopItems } = props;
	const { previousStep } = useRegistrationForm();
	const { trackShopAborted, trackShopCompleted } = usePMAnalytics();
	const navigate = useNavigate();

	const initialValues: ChosenShopItems = useMemo(
		() =>
			shopItems.reduce(
				(current, shopItem) => ({
					...current,
					[shopItem.id]: chosenShopItems[shopItem.id] || shopItem.minPerGuest || 0,
				}),
				{},
			),
		[chosenShopItems, shopItems],
	);

	const handleBack = useCallback(() => {
		const previousStepId = previousStep();

		if (previousStepId) {
			trackShopAborted(registrationFormId, previousStepId);
			navigate(`../${previousStepId}`);
		} else {
			navigate(`../landing-page`);
		}
	}, [navigate, previousStep, registrationFormId, trackShopAborted]);

	if (isLoading) {
		return (
			<Section>
				<Headline>{t("Shop")}</Headline>
				<Loading />
			</Section>
		);
	}

	if (!shopItems.length) {
		return <Navigate to="../summary" />;
	}

	return (
		<Section>
			<Headline>{t("Shop")}</Headline>
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					setChosenShopItems(values);
					trackShopCompleted(registrationFormId);
				}}
			>
				<Form>
					<ShopItemsWrapper>
						{shopItems.map((shopItem) => (
							<RegistrationShopItem key={shopItem.id} shopItem={shopItem} shopSettings={props.shopSettings} />
						))}
					</ShopItemsWrapper>
					<Actions>
						<Button type="button" onClick={handleBack}>
							{t("Back")}
						</Button>
						<SubmitButton color="primary">{t("Next")}</SubmitButton>
					</Actions>
				</Form>
			</Formik>
		</Section>
	);
};
