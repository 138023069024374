import { TFunction } from "i18next";
import { toDataField } from "~root/registration/provider/registrationFormProviderUtils";
import { RuleResult, RuleSet } from "~root/registration/ruleSets/ruleSet";
import {
	DataField,
	DataFieldType,
	IConsent,
	IRegistrationFormChildDataField,
	IRegistrationFormChildSection,
	IRegistrationFormStep,
	IRuleSet,
} from "~shared/types";
import { DataFieldValue, DataFieldValues } from "~shared/types/guest";
import { IRegistrationFormAvailableShowResource } from "~shared/types/registrationForm";
import { ConsentBody, ConsentValues, FilledSteps } from "./provider/registrationFormProviderContext";

export const flattenStepDataFields = (filledSteps: Record<number, Record<number, any>>) =>
	Object.values(filledSteps)
		.map((stepValues) =>
			Object.keys(stepValues).reduce(
				(all, dataFieldId) => ({
					...all,
					[dataFieldId]: stepValues[+dataFieldId],
				}),
				{} as Record<string, any>,
			),
		)
		.reduce(
			(all, values) => ({
				...all,
				...values,
			}),
			{} as Record<string, any>,
		);

export const getSectionsFromStep = (step: IRegistrationFormStep) =>
	step.children.filter((x) => x.entityType === "section") as IRegistrationFormChildSection[];
export const toDataFieldChildrenFromSection = (section: IRegistrationFormChildSection) =>
	section.section.children.filter((x) => x.entityType === "dataField") as IRegistrationFormChildDataField[];

export const createInitialValues = (dataFields: DataField[], dataFieldValues?: DataFieldValue[]): DataFieldValues =>
	dataFields.reduce((acc, curr) => {
		const dataFieldValue = dataFieldValues && dataFieldValues.find((value) => value.id === curr.id);
		let value;
		switch (curr.type) {
			case DataFieldType.CHECKBOX_GROUP:
				value = dataFieldValue && dataFieldValue.value !== null ? dataFieldValue.value : [];
				break;
			case DataFieldType.MULTI_SELECT_FIELD:
				value =
					dataFieldValue && dataFieldValue.value !== null
						? curr.options.filter((option) => dataFieldValue.value.includes(option.id))
						: [];
				break;
			case DataFieldType.RADIO:
				value = dataFieldValue && dataFieldValue.value !== null ? `${dataFieldValue.value}` : null;
				break;
			case DataFieldType.CHECKBOX:
				value = dataFieldValue && dataFieldValue.value !== null ? (dataFieldValue.value as boolean) : false;
				break;
			default:
				value = dataFieldValue?.value ?? null;
				break;
		}

		return {
			...acc,
			[curr.id]: value,
		};
	}, {});

export const toConsentValues = (consents: IConsent[], givenConsents: ConsentValues = {}) => {
	return consents
		.filter((consent) => consent.type != "notice")
		.reduce(
			(previousValue, currentValue) => ({
				...previousValue,
				[currentValue.id]: givenConsents[currentValue.id] ?? currentValue.type === "checkbox" ? false : "",
			}),
			{},
		);
};

export const toConsentErrors = (consents: IConsent[], values: ConsentValues, t: TFunction) => {
	return consents.reduce((res, c) => {
		const id = c.id.toString();
		if (!values[id] && c.required) {
			return {
				...res,
				[id]: t("Field is required"),
			};
		}
		return res;
	}, {});
};

export const mapConsentValuesToBody = (consents: ConsentValues): ConsentBody[] => {
	return Object.keys(consents).map((id) => ({
		id: id,
		value: consents[id] === "true" || consents[id] === true,
	}));
};

export const getStepsVisibility = (ruleSets: IRuleSet[], filledSteps: FilledSteps) => {
	const dataFieldValues = flattenStepDataFields(filledSteps);

	return mergeRuleResults(ruleSets.flatMap((ruleSet) => new RuleSet(ruleSet, dataFieldValues).getHiddenSteps()));
};

export const getSectionsVisibility = (
	steps: IRegistrationFormStep[],
	ruleSets: IRuleSet[],
	filledSteps: FilledSteps,
) => {
	const dataFieldValues = flattenStepDataFields(filledSteps);
	const hiddenSteps = getStepsVisibility(ruleSets, filledSteps);
	const sectionsInHiddenSteps = steps
		.filter((x) => hiddenSteps.includes(x.id))
		.flatMap((x) =>
			x.children.reduce((acc, x) => [...acc, ...(x.entityType === "section" ? [x.section.id] : [])], [] as number[]),
		);
	const _hiddenSections = mergeRuleResults(
		ruleSets.flatMap((ruleSet) => new RuleSet(ruleSet, dataFieldValues).getHiddenSections()),
	);

	return [...sectionsInHiddenSteps, ..._hiddenSections];
};

export const getDataFieldsVisibility = (
	steps: IRegistrationFormStep[],
	ruleSets: IRuleSet[],
	filledSteps: FilledSteps,
) => {
	const dataFieldValues = flattenStepDataFields(filledSteps);
	const hiddenSections = getSectionsVisibility(steps, ruleSets, filledSteps);
	const dataFieldsInHiddenSections = steps.flatMap((x) =>
		x.children.reduce((acc, x) => {
			if (x.entityType === "section" && hiddenSections.includes(x.section.id)) {
				return x.section.children.reduce((acc, y) => {
					if (y.entityType === "dataField") {
						return [...acc, `${x.section.id}-${y.dataField.id}`];
					}
					return acc;
				}, acc);
			}
			return acc;
		}, [] as string[]),
	);

	const _hiddenDataFields = mergeRuleResults(
		ruleSets.flatMap((ruleSet) => new RuleSet(ruleSet, dataFieldValues).getHiddenDataFields()),
	);
	return [...dataFieldsInHiddenSections, ..._hiddenDataFields];
};

export function findNextStep(
	hiddenSteps: number[],
	steps: IRegistrationFormStep[],
	stepId: number | null,
): number | null {
	if (stepId === null) {
		const firstStep = steps[0];
		if (hiddenSteps.includes(firstStep.id)) {
			return findNextStep(hiddenSteps, steps, firstStep.id);
		}
		return firstStep.id;
	}
	const idx = steps.findIndex((x) => x.id === stepId);

	if (idx + 1 > steps.length - 1) {
		return null;
	}
	const nextStep = steps[idx + 1];
	if (hiddenSteps.includes(nextStep.id)) {
		return findNextStep(hiddenSteps, steps, nextStep.id);
	}
	return nextStep.id;
}

export function findPrevStep(
	hiddenSteps: number[],
	steps: IRegistrationFormStep[],
	stepId: number | null,
): number | null {
	if (stepId === null) {
		const lastStep = steps[steps.length - 1];
		if (hiddenSteps.includes(lastStep.id)) {
			return findPrevStep(hiddenSteps, steps, lastStep.id);
		}
		return lastStep.id;
	}
	const idx = steps.findIndex((x) => x.id === stepId);

	if (idx - 1 === -1) {
		return null;
	}
	const prevStep = steps[idx - 1];
	if (hiddenSteps.includes(prevStep.id)) {
		return findPrevStep(hiddenSteps, steps, prevStep.id);
	}
	return prevStep.id;
}

const mergeRuleResults = (ruleResults: RuleResult[]) => {
	const resultMap = ruleResults.reduce(
		(res, step) => {
			if (!res[step.id]) {
				res[step.id] = step.show;
			}
			return res;
		},
		{} as { [key: number | string]: boolean },
	);

	return Object.keys(resultMap)
		.map((k: any) => ({ id: k, show: resultMap[k] }))
		.filter((r) => !r.show)
		.map((r) => (r.id.toString().includes("-") ? r.id : Number(r.id)));
};

export const getDataFieldsFromForm = (registrationForm: IRegistrationFormAvailableShowResource) =>
	registrationForm.steps
		.flatMap(getSectionsFromStep)
		.filter((section) => section.section.type === "default")
		.flatMap((x) => toDataFieldChildrenFromSection(x))
		.map(toDataField);
