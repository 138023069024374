import { isEmail } from "@dgs/core";
import { TFunction } from "i18next";
import { v4 } from "uuid";
import { config } from "~root/config";
import { DataField, DataFieldOption, DataFieldType, IRegistrationFormChildDataField } from "~shared/types";
import { DataFieldValue, DataFieldValues } from "~shared/types/guest";
import { DataFieldErrors } from "./registrationFormProviderContext";

export const PHONE_REGEX = /^[+]*[-\s./0-9]{0,4}[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;

export const getDataFieldError = (values: DataFieldValues, { id, type }: DataField, t: TFunction): string | null => {
	const value = values[id];

	if (type === DataFieldType.FILE && value instanceof File) {
		const file = value as File;
		if (file.size > config.uploadedFileMaxSize * 1000000) {
			return t("Max. {{size}} MB file size", { size: config.uploadedFileMaxSize });
		}
	}
	if (type === DataFieldType.EMAIL && value?.length > 0 && !isEmail(value)) {
		return t("Invalid email address");
	}
	if (type === DataFieldType.PHONE && value?.length > 0) {
		if (value.length > 20) {
			return t("This field may not exceed 20 characters");
		} else if (!PHONE_REGEX.test(value)) {
			return t("Invalid phone number");
		}
	}
	if (type === DataFieldType.TEXT && value?.length > 0 && value.length > 181) {
		return t("This field may not exceed 181 characters");
	}
	if (type === DataFieldType.URL && value?.length > 0 && !/^https?:\/\//.test(value)) {
		return t("Invalid URL format");
	}
	return null;
};

export const toDataFieldRequestValues =
	(dataFieldValues: DataFieldValues, formData?: FormData) =>
	(dataField: DataField): DataFieldValue => {
		if (dataField.type === DataFieldType.CHECKBOX_GROUP) {
			return {
				id: dataField.id,
				value: dataFieldValues[dataField.id] ?? [],
			};
		} else if (dataField.type === DataFieldType.MULTI_SELECT_FIELD) {
			return {
				id: dataField.id,
				value: dataFieldValues[dataField.id].map((option: DataFieldOption) => option.id) ?? [],
			};
		} else if (dataField.type === DataFieldType.RADIO) {
			return {
				id: dataField.id,
				value: typeof dataFieldValues[dataField.id] === "string" ? parseInt(dataFieldValues[dataField.id]) : null,
			};
		} else if (dataField.type === DataFieldType.FILE) {
			if (typeof dataFieldValues[dataField.id] === "string") {
				// not updated file
				return {
					id: dataField.id,
					value: dataFieldValues[dataField.id],
				};
			} else if (dataFieldValues[dataField.id] instanceof File) {
				// new uploaded file
				const fileId = v4();
				const file: File = dataFieldValues[dataField.id];

				// TODO: Fix null check in refactoring ticket
				formData?.append(fileId, file);

				return {
					id: dataField.id,
					value: fileId,
				};
			} else {
				// deleted file
				return {
					id: dataField.id,
					value: null,
				};
			}
		}
		return {
			id: dataField.id,
			value: dataFieldValues[dataField.id],
		};
	};

export const toDataField = (child: IRegistrationFormChildDataField) => child.dataField;

export const toDataFieldTypeValidationErrors =
	(values: DataFieldValues, t: TFunction) =>
	(errors: DataFieldErrors, dataField: DataField): DataFieldErrors => {
		const dataFieldError = getDataFieldError(values, dataField, t);
		return {
			...errors,
			...(dataFieldError ? { [dataField.id]: dataFieldError } : {}),
		};
	};
