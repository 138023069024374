import {
	CheckboxGroupField,
	DateField,
	DateTimeField,
	MultiSelectField,
	RadioGroupField,
	RadioOption,
	SingleSelectField,
	TextField,
	TextareaField,
	TimeField,
	ValidatableCheckboxField,
} from "@dgs/core";
import { format, parseISO } from "date-fns";
import React, { FC } from "react";
import { DataField, DataFieldOption, DataFieldType, Rule } from "~shared/types";
import { RegistrationFormFileField } from "./RegistrationFormFileField";

interface Props {
	dataField: DataField;
	required?: boolean;
	readOnly?: boolean;
	validationRules?: Rule[];
}
export const RegistrationFormDataFieldBase: FC<Props> = ({ dataField, required, readOnly, validationRules = [] }) => {
	const baseProps = {
		key: dataField.id,
		label: dataField.label,
		name: dataField.id.toString(),
		required: required,
		readOnly,
	};
	const props = {
		...baseProps,
		description: dataField.description,
		placeholder: dataField.placeholder,
	};

	switch (dataField.type) {
		case DataFieldType.SELECT_FIELD:
			return (
				<SingleSelectField
					{...props}
					clearable={true}
					options={dataField.options}
					getLabel={(option: DataFieldOption) => option.value}
					getValue={(option: DataFieldOption) => option.id}
				/>
			);
		case DataFieldType.MULTI_SELECT_FIELD:
			return (
				<MultiSelectField {...props} options={dataField.options} getLabel={(option: DataFieldOption) => option.value} />
			);
		case DataFieldType.CHECKBOX_GROUP:
			return (
				<CheckboxGroupField
					{...props}
					disabled={readOnly}
					options={dataField.options.map((x) => ({
						value: x.id,
						name: x.value,
					}))}
				/>
			);
		case DataFieldType.FILE:
			return <RegistrationFormFileField {...props} dataFieldId={dataField.id} />;
		case DataFieldType.RADIO:
			const options: RadioOption[] = dataField.options.reduce(
				(acc, cur) => [
					...acc,
					...[
						{
							value: JSON.stringify(cur.id),
							name: cur.value,
						},
					],
				],
				[] as RadioOption[],
			);
			return (
				<RadioGroupField {...baseProps} options={options} description={dataField.description} disabled={readOnly} />
			);
		case DataFieldType.DATE:
			return (
				<DateField
					{...props}
					description={dataField.description}
					min={getMinMaxValue("min", validationRules)}
					max={getMinMaxValue("max", validationRules)}
				/>
			);
		case DataFieldType.TIME:
			return <TimeField {...baseProps} description={dataField.description} />;
		case DataFieldType.DATETIME:
			return (
				<DateTimeField
					{...baseProps}
					description={dataField.description}
					min={getMinMaxValue("min", validationRules)}
					max={getMinMaxValue("max", validationRules)}
				/>
			);
		case DataFieldType.CHECKBOX:
			return <ValidatableCheckboxField {...baseProps} disabled={readOnly} />;
		case DataFieldType.NUMBER:
			return <TextField type="number" {...props} />;
		case DataFieldType.TEXT:
			return <TextField {...props} />;
		case DataFieldType.EMAIL:
			return <TextField {...props} />;
		case DataFieldType.LONG_TEXT:
			return <TextareaField {...props} />;
		case DataFieldType.URL:
			return <TextField {...props} />;
		case DataFieldType.PHONE:
			return <TextField {...props} />;
		case DataFieldType.CRYPT:
			return <TextField {...props} />;
	}

	return null;
};

const getMinMaxValue = (type: "min" | "max", validationRules: Rule[]): string => {
	const validationRule = validationRules.find((value) => value.type === type);
	if (validationRule) {
		return format(parseISO(validationRule.value as string), "yyyy-MM-dd");
	}
	return "";
};
