import { Loading, RenderView, Section, SubmitButton, apiIsOK, useAnalytics, useToasts } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RegistrationReplacementAnalyticsEvent } from "~root/analytics/analyticsEvents";
import { registrationFormService } from "~shared/api/registrationForms";
import { useRegistrationForm } from "../provider/registrationFormProviderContext";
import { RegistrationFormDataFieldBase } from "../RegistrationFormDataFieldBase";
import { createInitialValues } from "../registrationFormUtils";

const Wrapper = styled(Section)`
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: end;
`;

export const RegistrationNominateReplacementPage = () => {
	const { t } = useTranslation();
	const { track } = useAnalytics();
	const { showToast } = useToasts();
	const navigate = useNavigate();
	const {
		registrationForm: { id, guest },
	} = useRegistrationForm();
	const { data, isLoading } = useQuery({
		queryKey: registrationFormService.keys.showConfirmReplacement(id),
		queryFn: () => registrationFormService.showConfirmReplacement(id).then((x) => x.data.data),
	});

	const handleSubmit = useCallback(
		async (dataFields: Record<number, any>) => {
			const response = await registrationFormService.nominateReplacement(
				id,
				Object.keys(dataFields).map((id) => ({ id: id as unknown as number, value: dataFields[id as any] })),
				guest?.id,
			);

			if (apiIsOK(response)) {
				navigate("../replaced", { replace: true });
				track(new RegistrationReplacementAnalyticsEvent(id, new Date()));
			} else {
				showToast({ body: t("An error has occurred"), type: "error", title: t("Nominate replacement") });
			}
		},
		[guest?.id, id, navigate, showToast, t, track],
	);

	if (isLoading || !data) {
		return <Loading />;
	}

	return (
		<>
			{data.block && <RenderView block={data.block} />}
			<Wrapper color="content">
				<Formik initialValues={createInitialValues(data.dataFields)} onSubmit={handleSubmit}>
					<Form>
						{data.dataFields.map((c) => (
							<RegistrationFormDataFieldBase key={c.id} dataField={c} required={true} />
						))}
						<ButtonWrapper>
							<SubmitButton>{t("registration.submit")}</SubmitButton>
						</ButtonWrapper>
					</Form>
				</Formik>
			</Wrapper>
		</>
	);
};
