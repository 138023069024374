import { Card, CardBody, LinkButton } from "@dgs/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
`;

const StyledCardBody = styled(CardBody)`
	display: grid;
	grid-auto-rows: 1fr;
	padding: ${({ theme }) => theme.spacing(10)};
	gap: ${({ theme }) => theme.spacing(2)};
`;
export const RegistrationPaymentErrorPage = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<Card>
				<StyledCardBody>
					<div>{t("Your payment could not be completed successfully.")}</div>
					<LinkButton color="primary" to="..">
						{t("Try again")}
					</LinkButton>
				</StyledCardBody>
			</Card>
		</Wrapper>
	);
};
