import { format, isBefore, isEqual, parse, parseISO } from "date-fns";
import { DataFieldType } from "~shared/types";
import { RuleTypes } from "~shared/types/rules";
import { BaseRule, TranslatedError, TranslatedErrorProps } from "./baseRule";

export class MaxRuleImpl extends BaseRule {
	type: RuleTypes = RuleTypes.MAX;

	isValid(): boolean {
		if (this.value === null || this.value === undefined) {
			return this.isNullValid;
		}

		switch (this.dataField.type) {
			case DataFieldType.CRYPT:
			case DataFieldType.NUMBER:
				return (this.value as number) <= (this.ruleValue as number);
			case DataFieldType.TIME:
				return (this.value as string) <= (this.ruleValue as string);
			case DataFieldType.DATETIME:
				return (
					isBefore(parseISO(this.value as string), parseISO(this.ruleValue as string)) ||
					isEqual(parseISO(this.value as string), parseISO(this.ruleValue as string))
				);
			case DataFieldType.DATE:
				return (
					isBefore(
						parse(this.value as string, "yyyy-MM-dd", new Date()),
						parse(this.ruleValue as string, "yyyy-MM-dd", new Date()),
					) ||
					isEqual(
						parse(this.value as string, "yyyy-MM-dd", new Date()),
						parse(this.ruleValue as string, "yyyy-MM-dd", new Date()),
					)
				);
			case DataFieldType.MULTI_SELECT_FIELD:
			case DataFieldType.CHECKBOX_GROUP:
				return (this.value as []).length <= (this.ruleValue as number);
		}
		return false;
	}

	getErrorMessage({ locale }: TranslatedErrorProps): TranslatedError {
		switch (this.dataField.type) {
			case DataFieldType.DATE:
				return {
					key: "Field has maximum value of {{value}}",
					values: { value: format(parse(this.ruleValue as string, "yyyy-MM-dd", new Date()), "P", { locale }) },
				};
			case DataFieldType.DATETIME:
				return {
					key: "Field has maximum value of {{value}}",
					values: { value: format(parseISO(this.ruleValue as string), "Pp", { locale }) },
				};
			case DataFieldType.CHECKBOX_GROUP:
			case DataFieldType.MULTI_SELECT_FIELD:
				return {
					key: "Field has maximum {{count}} values",
					values: { count: Number(this.ruleValue) },
				};
			default:
				return {
					key: "Field has maximum value of {{value}}",
					values: { value: this.ruleValue },
				};
		}
	}
}
