import { Card, CardBody, H2, IconButton } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IPaymentProvider } from "~shared/types/paymentProvider";

const Wrapper = styled.div`
	display: grid;
	grid-template-rows: max-content 1fr;
	gap: ${({ theme }) => theme.spacing(5)};
`;

const PaymentProviderWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(5)};
`;

const StyledCard = styled(Card)`
	width: 200px;
`;

const StyledCardBody = styled(CardBody)`
	display: flex;
	height: 100%;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(5)};
`;

interface Props {
	paymentProviders: IPaymentProvider[];
	chosenPaymentProvider: IPaymentProvider | null;
	onChosePaymentProvider: (paymentProvider: IPaymentProvider) => void;
}
export const RegistrationShopPaymentProviders: FC<Props> = ({
	paymentProviders,
	chosenPaymentProvider,
	onChosePaymentProvider,
}) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<H2>{t("Payment provider")}</H2>
			<PaymentProviderWrapper>
				{paymentProviders.map((provider) => (
					<PaymentProviderCard
						key={provider.id}
						provider={provider}
						onClick={() => onChosePaymentProvider(provider)}
						isActive={chosenPaymentProvider?.id === provider.id}
					/>
				))}
			</PaymentProviderWrapper>
		</Wrapper>
	);
};

interface PaymentProviderCardProps {
	provider: IPaymentProvider;
	isActive: boolean;
	onClick: (provider: IPaymentProvider) => void;
}
const PaymentProviderCard: FC<PaymentProviderCardProps> = ({ provider, isActive, onClick }) => {
	const { t } = useTranslation();
	const handleClick = useCallback(() => onClick(provider), [onClick, provider]);

	return (
		<StyledCard onClick={handleClick} $clickable={true}>
			<StyledCardBody>
				<IconButton
					icon="check"
					color={isActive ? "primary" : "default"}
					type="button"
					title={t("Use this payment provider")}
				/>
				<span>{provider.name}</span>
			</StyledCardBody>
		</StyledCard>
	);
};
