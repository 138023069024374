import React, { FC } from "react";
import styled from "styled-components";
import { IConsent } from "~shared/types";
import { RegistrationConsent } from "./RegistrationConsent";

const Consents = styled.div`
	display: grid;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(4)};
	padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
	consents: IConsent[];
}

export const ConsentFormFields: FC<Props> = ({ consents }) => (
	<Consents>
		{consents.map((consent) => (
			<RegistrationConsent key={consent.id} consent={consent} />
		))}
	</Consents>
);
